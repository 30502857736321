
@import "root";

.info__circle {
    margin-top: 225px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.info__circle > div {
    width: 43%;
    gap: 70px;
}

.info__title {
    font-family: $font-family;
    font-size: 40px;
    line-height: 70px;
    text-transform: uppercase;
    color: $third-color;
    text-shadow: 2px -6px 4px rgba(0, 0, 0, 0.25);
}

.info__description {
    font-family: $font-family;
    font-size: 24px;
    line-height: 40px;
    color: $fourth-color;
    text-align: left;
}

.circle__title {
    font-family: $font-family;
    font-size: 24px;
    line-height: 31px;
    color: $fourth-color;
}

.info__representation {
    position: relative;
    margin-top: 100px;
    width: 528px;
    height: 528px;
    border: 5px solid $third-color;
    box-shadow: inset 9px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
}

.circle {
    position: absolute;
    width: 159px;
    height: 157px;
    box-shadow: 9px 0 0 rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    align-items: center;
}

.not-active {
    background: $first-color;
}

.active_circle {
    background: $third-color;
}

.comp {
    top: 401px;
    left: 38px;
}

.paint {
    left: 381px;
    top: 31px;
}

.info {
    left: 170px;
    top: -80px;
}

.seedling {
    left: 437px;
    top: 241px;
}

.book{
    left: 277px;
    top: 401px;
}

.shield{
    left: -77px;
    top: 222px;
}

.search{
    left: -40px;
    top: 31px;
}

.info__text p {
    width: 77%;
}

.mobile-swiper {
    display: none;
}

@media only screen and (max-width: 1381px) {
    .info__circle {
        flex-wrap: wrap;
    }

    .info__images {
        display: none;
    }
    .info__circle > div {
        width: 95%;
    }
    .info__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 90%;
        text-align: justify;
    }

    .info__text p {
        width: 100%;
    }

    .mobile-swiper {
        display: block;
    }

    .mobile-swiper {
        width: 80%;
        margin: 0 auto 25px;
    }
}

@media only screen and (max-width: 767px){

    .info__text p {
        width: 90%;
        margin: auto;
    }
    .info__description{
        font-size: 20px;
        line-height: 40px;
    }
}
