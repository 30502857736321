@import "root";
.main__goods {
    display: flex;
    grid-gap: 3rem;
    padding: 3rem;
    box-shadow: 0 1px 0 0px rgba(0, 0, 0, 0.12);
}

.goods__photos {
    display: flex;
    justify-content: center;
}
.goods__main_preview {
    position: relative;
    width: 100%;
    height: 350px;
    border-radius: 0.6rem;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        //object-fit: cover;
    }
}
.goods__photo_list {
    list-style: none;
    margin: 2rem 0 0;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;

    li {
        display: inline-block;
    }
    img {
        width: 90px;
        height: 90px;
        object-fit: cover;
        border-radius: 0.6rem;
    }
}

.goods__details {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.goods__company {
    color: $third-color;
    font-family: sans-serif;
    margin: 0.5rem 0;
}
.goods__title {
    font-size: 2rem;
    color: $fourth-color;
    font-family: sans-serif;
    margin: 0;
}
.goods__description {
    margin: 1rem 0;
    line-height: 1.3;
    color: $fourth-color;
    font-family: sans-serif;
}
.goods__sale__price {
    display: inline-block;
    margin: 0 0.6rem 0 0;
    color: $third-color;
    font-family: sans-serif;
}
.goods__discount {
    color: $first-color;
    background: $fourth-color;
    padding: 0.1rem 0.5rem;
    border-radius: 0.1rem;
    font-family: sans-serif;
}
.goods__original__price {
    color: #999;
    text-decoration: line-through;
    margin: 0.6rem 0;
    font-family: sans-serif;
}

.main__related {
    margin: 2rem 0;
}

.related__title {
    color: $third-color;
    margin: 0 0 1.5rem;
}

.related__prev_next {
    display: none;
}
.related__prev_next .related__prev,
.related__prev_next .related__next {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-size: 25px;
    background: none;
    border: none;
    padding: 1rem 1.5rem;
    color: #412;
    cursor: pointer;
    border-radius: 50%;

    &:hover {
        color: #000;
        background: #0001;
    }
}
.related__prev_next .related__next {
    right: 0;
    left: auto;
}

.related__goods_viewpoint {
    position: relative;
}

.related__goods {
    list-style: none;
    display: flex;
    gap: 0 2rem;
    padding: 0;
    margin: 0;
    overflow: scroll;
    transition: 0.3s all;
    margin-left: 0vw;

    li {
        flex: 1;
    }
    img {
        width: 100%;
        height: 15vw;
        object-fit: cover;
        border-radius: 0.2rem;
    }
}

@media screen and (max-width: 752px) {

    .main__goods {
        padding: 0;
    }

    .goods__main_preview {
        border-radius: 0;
        height: 300px;
    }

    .goods__photo_list {
        display: none;
    }

    .goods__details {
        padding: 0 2rem 2rem;
    }

    .related__title {
        margin-left: 2rem;
    }

    .related__goods {
        gap: 0;
        margin-left: 0vw;

        img {
            width: 100vw;
            height: 300px;
            border-radius: 0;
        }
    }

    .related__prev_next {
        display: inline-block;
    }
}

@media screen and (max-width: 862px) {
    .main__goods {
        flex-direction: column;
    }
}
