
@import "root";

.product__card {
    height: 530px;
    width:300px;
    background: $first-color;
    box-shadow: 10px 10px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px 20px;
    padding: 10px;
    margin-top: 10px;
}

.product__card-logo-block {
    display: flex;
    justify-content: right;
}

.product__card-logo img {
    width: 66px;
    height: 31px;
}

.product_presentation {
    display: flex;
    /*flex-direction: column;*/
    justify-content: center;
}

.product_img {
    width: 200px;
    height: 200px;
    background-color: $fourth-color;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.product__description {
    display: flex;
    justify-content: center;
}

.product__description > div {
    margin-top: 32px;
    width: 70%;
}

.product__description p {
    font-family: sans-serif;
    font-size: 18px;
    line-height: 23px;
    color: $fourth-color;
}

.product__buttons {
    margin-top: 22px;
    display: flex;
    justify-content: space-around;
}

.old-price, .new-price {
    width: 125px;
    height: 42px;
    border-radius: 20px 5px;
    font-family: sans-serif;
    font-size: 16px;
    line-height: 21px;
    color: $third-color;
    cursor: auto !important;
}

.product__add {
    width: 180px;
    height: 60px;
    background: $second-color;
    border: 3px solid $third-color;
    border-radius: 5px 20px;
    font-family: sans-serif;
    font-size: 16px;
    line-height: 21px;
    color: $fourth-color;
    cursor: pointer;
    outline: none;
}

.old-price {
    text-decoration: line-through;
    background: $fourth-color;
    outline: none;
    border: none;
}

.new-price {
    background: $third-color;
    color: $fourth-color;
    outline: none;
    border: none;
}

.product__footer {
    margin: 41px auto;
    width: 70%;
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
}

.product__footer p {
    font-family: $font-family;
    font-size: 13px;
    line-height: 17px;
    color: #CCFBFF;
}

.page__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 45px;
}

.__page {
    border: 1px solid $first-color;
    padding: 10px;
    user-select: none;
    cursor: pointer;
    color: $fourth-color;
}

.current-page {
    border: 2px solid $third-color;
    color: $third-color;
}
