@import "root";

.partners {
    width: 75px;
    position: absolute;
    background: transparent;
    border: 1px solid $third-color;
    box-sizing: border-box;
    box-shadow: -8px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px 0px;
    padding: 1px;
    top: 88px;
    left: 0;
    overflow: hidden;
    z-index: 55555;
}
.partners>div>img{
    width: 72px;
    margin: auto;
}

@media only screen and (max-width: 1056px) {
    .partners {
        top: 60px;
    }
}

@media only screen and (max-width: 768px) {
    .partners{
        display: none;
    }
}
