/* Form controls */

label {
    font-size: $font-size-sm;
}

.form-control {
    font-size: $input-btn-font-size;

    &:focus {
        &::placeholder {
            color: $input-focus-placeholder-color;
        }
    }
}


/* Textareas */

textarea[resize="none"] {
    resize: none!important;
}

textarea[resize="both"] {
    resize: both!important;
}

textarea[resize="vertical"] {
    resize: vertical!important;
}

textarea[resize="horizontal"] {
    resize: horizontal!important;
}


/* Alternative styles */

.form-control-muted {
    background-color: $input-muted-bg;
    border-color: $input-muted-bg;
    box-shadow: none;

    &:focus {
        background-color: $input-muted-bg;
        //box-shadow: none;
    }
}

.form-control-alternative {
    box-shadow: $input-alternative-box-shadow;
    border: 0;
    transition: box-shadow .15s ease;

    &:focus {
        box-shadow: $input-focus-alternative-box-shadow;
    }
}

// Sizes

.form-control-lg {
    font-size: $font-size-base;
}

// Validation

.has-success,
.has-danger {
    position: relative;

    &:after {
        width: 19px;
        height: 19px;
        line-height: 19px;
        text-align: center;
        font-family: 'NucleoIcons';
        display: inline-block;
        position: absolute;
        right: 15px;
        top: 2px;
        transform: translateY(50%);
        border-radius: 50%;
        font-size: 9px;
        opacity: 1;
    }
}

.has-success {
    &:after {
        content: "\ea26";
        color: darken($form-feedback-valid-color, 18%);
        background-color: $form-feedback-valid-bg;
    }
    .form-control {
        background-color: $input-focus-bg;

        &:focus {
            border-color: $input-focus-border-color;
        }

        // Placeholder
        &::placeholder {
            color: $form-feedback-valid-color;
        }
    }

    &.focused {
        &:after {
            //opacity: 0;
        }
    }
}

.has-danger {
    &:after {
        content: "\ea53";
        color: darken($form-feedback-invalid-color, 18%);
        background-color: $form-feedback-invalid-bg;
    }
    .form-control {
        background-color: $input-focus-bg;

        &:focus {
            border-color: $input-focus-border-color;
        }

        // Placeholder
        &::placeholder {
            color: $form-feedback-invalid-color;
        }
    }

    &.focused {
        &:after {
            //opacity: 0;
        }
    }
}
