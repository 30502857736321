.home-swiper {
    margin: 156px auto;
    width: 90%;
}
.home_swiper-text {
    position: relative;
    width: 80%;
    margin: 20px auto;
    span{
        font-family: 'Raleway', sans-serif;
        font-size: 64px;
        line-height: 83px;
        text-shadow: 2px -6px 4px rgba(0, 0, 0, 0.25);
    }
}

.prpl_txt {
    color: #4A1771;
}
.yel_txt {
    color: #eeee0e;
}

.sp_1 {
    position: absolute;
    top: -41px;
    left: 155px;
}

.get_more {
    margin-top: 75px;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 767px) {
    .sp_1 {
        top: 65px;
        left: 15px;
    }

    .sp_1 img {
        width: 100%;
    }
    .home_swiper-text span{
        font-size: 24px;
        line-height: 48px;
    }
}
