@import "root";

.menu {
    width: 100%;
    display: flex;
    justify-content: space-around;
    height: 88px;
    position: fixed !important;
    background: $first-color;
    box-sizing: border-box;
    z-index: 8888;
    box-shadow: 0 10px 4px rgba(0, 0, 0, 0.25);
}

.menu__left_side, .menu__right_side {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #222831 !important;
}

.menu__left_side {
    width: 70%;
}

.menu__right_side {
    width: 30%;
}

.menu__right_side > div {
    width: 33%;
    justify-content: center;
}

.menu__right_side > .adapt-shop {
    width: 15%;
}

.menu__items {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: $fourth-color;
    text-decoration: none;
    font-family: $font-family;
    user-select: none;
    cursor: pointer;
}

.router-link-active {
    color: $third-color;
    font-weight: bold !important;
    text-shadow: 0 15px 4px rgba(0, 0, 0, 0.25);
}

.menu__right_side > .lang {
    display: flex;
    justify-content: space-around;
}

.login {
    display: flex;
    justify-content: center;
    gap: 3px;
    align-items: center;
}

.adapt-menu-mobile {
    display: none;
}

.adapt-shop > div {
    display: flex;
    justify-content: center;
}

.menu__items > .lang-icon {
    width: 30px;
    height: 20px;
    cursor: pointer;
}

.menu__items > .user-img {
    width: 30px;
    height: 30px;
}

.menu__items > .logo {
    margin-top: 10px;
    width: 120px;
}

@media only screen and (max-width: 1456px) {

    .menu__items {
        font-size: 13px;
    }
}

@media only screen and (max-width: 1056px) {
    .menu {
        height: 60px;
    }
    .menu, .menu__left_side, .menu__right_side {
        flex-direction: column;
        justify-content: left;
        text-align: left;
        z-index: 999999;
    }

    .menu, .menu__left_side > div, .menu__right_side > div {
        width: 100%;
    }

    .login {
        justify-content: left;
    }

    .menu__items {
        padding: 20px 20px;
    }
    .adapt-logo, .adapt-shop {
        display: none;
    }

    .menu__dropdown {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .menu__btn_open {
        width: 100px;
        height: 40px;
        background: $fourth-color;
        border: 2px solid $third-color;
        box-sizing: border-box;
        border-radius: 8px;
        text-align: center;
        vertical-align: center;
        display: flex;
        justify-content: space-around;
        align-items: center;
        cursor: pointer;
    }

    .menu_txt {
        font-family: $font-family;
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
        color: #000;
    }

    .adapt-menu-mobile {
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: absolute;
        width: 90%;
        left: 5%;
    }

    .menu__left_side, .menu__right_side {
        position: absolute;
        top: -500px;
        width: 100%;
        transition: .5s;
    }

}
