.sphare__block {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    position: absolute;
    z-index: -9999999999;
    opacity: .4;
}

.sp_3, .sp_4, .sp_5, .sp_6, .sp_7 {position: absolute;}

.sp_3 {
    right: 0px;
    top: 1597px;
}

.sp_4 {
    right: 0px;
    top: 2154px;
}

.sp_5 {
    left: 0px;
    top: 2441px;
}

.sp_6 {
    left: 0px;
    top: 2441px;
}

.sp_7 {
    top: 4700px;
}

.sp_7 img {
    width: 100%;
}

@media only screen and (max-width: 1056px) {
    .sphare__block {
        display: none;
    }
}
