
@import "root";

.about_cars {
    width: 80%;
    margin: auto;
    padding: 100px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.about {
    width: 80%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.about > div,
.about_cars > div {
    width: 45%;
    height: 600px;
    margin-bottom: 50px;
}

.about__car_image {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.about__car_image > h1 {
    font-size: 40px;
    font-family: $font-family;
    font-weight: normal;
    font-style: normal;
    color: $third-color;
}

.about__description,
.about__car_image-circle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.about__car_image-circle {
    width: 500px;
    height: 500px;
    background: rgba(47, 47, 180, 0.82);
    border: 10px solid #4a1771;
    border-radius: 50%;
}

.about__description-title {
    color: $fourth-color;
    font-family: $font-family;
    font-size: 44px;
    line-height: 60px;
}

.about__description-title span {
    color: #e6b0d5;
    font-family: "Raleway", sans-serif;
}

.about__description_container {
    width: 80%;
    margin: 0 auto 150px;
}

.about__description-text {
    font-size: 20px;
    margin-top: 30px;
    line-height: 31px;
    color: $fourth-color;
    font-family: $font-family;
}

.about__description-text-2 {
    font-size: 20px;
    margin-top: 30px;
    line-height: 31px;
    color: $fourth-color;
    font-family: $font-family;
}

.btn-container {
    margin-top: 47px;
    display: flex;
    justify-content: left;
}

.about__description-text-p {
    font-size: 20px;
    line-height: 31px;
    color: $fourth-color;
    margin-top: 30px;
    font-family: $font-family;
}

.about__description-title-blue {
    font-size: 44px;
    line-height: 60px;
    margin-bottom: 30px;
    text-align: center;
    width: 200px;
    color: $fourth-color;
    background: #09355d;
    box-shadow: -9px 18px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    font-family: $font-family;
}

.about__description-title-red {
    font-size: 44px;
    line-height: 60px;
    margin-bottom: 30px;
    text-align: center;
    width: 200px;
    color: $fourth-color;
    background: #c20e19;
    box-shadow: -9px 18px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    font-family: $font-family;
}

.about__description-title-yellow {
    font-size: 44px;
    line-height: 60px;
    margin-bottom: 30px;
    text-align: center;
    width: 200px;
    color: black;
    font-weight: bold;
    background: #fcc000;
    box-shadow: -9px 18px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    font-family: $font-family;
}

@media only screen and (max-width: 1580px) {
    .about > div {
        width: 50%;
    }
}

@media only screen and (max-width: 1281px) {
    .about__car_image-circle {
        width: 500px;
        height: 500px;
    }
}

@media only screen and (max-width: 767px) {

    .about_component > div {
        width: 95%;
        height: auto;
        margin-top: 30px;
    }

    .about__car_image-circle {
        height: 300px;
    }
    .about_cars{
        padding: 50px 0;
    }
    .about__car_image > h1 {
        font-size: 24px;
        margin-top: 20px;
    }
}
