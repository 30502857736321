.presentation, .information {
    display: flex;
    justify-content: center;
    gap: 118px;
}

.presentation > div, .information > div {
    width: 40%;
}

.breadcrumbs {
    margin-top: 57px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.breadcrumbs div {
    width: 85%;
    background: #090949;
    border: 1px solid #090949;
    box-sizing: border-box;
    border-radius: 5px;
    height: 50px;
    padding-top: 11px;
}

.breacrumbs__list {
    list-style-type: none;
    display: flex;
    justify-content: left;
    gap: 36px;
}

.breacrumbs__list:first-child {
    margin-left: 25px;
}

.breacrumbs__list li {
    height: 19px;
    font-family: sans-serif;
    font-size: 20px;
    line-height: 26px;
    text-transform: capitalize;
    color: #CCFBFF;
}

.presentation {
    margin-top: 100px;
}

.presentation__image {
    height: 503px;
    width: 423px;
    background: #090949;
    box-shadow: 10px 10px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px 20px;
}

.presentation__image-logo {
    display: flex;
    justify-content: right;
    padding: 13px 13px 0px 0px;
}

.presentation__image-logo img {
    width: 90px;
    height: 42px;
}

.presentation__image-img {
    border-radius: 5px;
    display: flex;
    justify-content: center;
}

.presentation__image-img img {
    width: 353px;
    height: 353px;
}

.ttk .title {
    font-family: sans-serif;
    font-weight: bold;
    font-size: 30px;
    line-height: 39px;
    color: #090949;
}

.ttk_description {
    margin-top: 50px;
    display: flex;
    justify-content: left;
    gap: 50px;
}

.ttk_description-title p, .ttk_description-description p {
    font-family: sans-serif;
    font-size: 24px;
    line-height: 31px;
    color: #000000;
    margin-bottom: 10px;
}

.price {
    width: 187px;
    height: 55px;
    font-family: sans-serif;
    font-size: 24px;
    line-height: 31px;
    color: #090949;
    text-align: center;
    padding-top: 13px;
}

.old_price {
    text-decoration: line-through;
    background: #DDF2F3;
    border: 1px solid #4CBAF8;
    box-sizing: border-box;
    border-radius: 20px 5px;
}

.new_price {
    background: #E31A56;
    border-radius: 5px 20px;
}

.btns__action {
    display: flex;
    justify-content: left;
    margin-top: 50px;
}

.card-body>.btns__action{
    margin-top: 0px;
}

.btns__action > div {
    width: 60px;
    height: 60px;
    background: #DDF2F3;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}

.card-body>.btns__action > div {
    width: 50px;
    height: 45px;
}

.btns__action > div >img{
    width: 20%;
    user-select: none;
}
.btns__add {
    margin-top: 40px;
    display: flex;
    justify-content: left;
    gap: 32px;
}

.btns__add .price {
    color: #fff;
}

.heart {
    text-align: center;
    padding-top: 17px;
    background-color: #fff;
    width: 51px;
    height: 51px;
    background: #DDF2F3;
    border-radius: 20px;
}

.colors_types {
    display: flex;
    justify-content: center;
    gap: 15px;
    background-color: #fff;
    height: 83px;
    border-radius: 20px;
    margin-top: 92px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.information .colors_types {
    width: 400px;
}

.colors_types div {
    width: 74px;
    height: 74px;
    border-radius: 20px
}

.single__info {
    margin-top: 30px;
}

.single__info p {
    font-family: sans-serif;
    font-size: 18px;
    line-height: 23px;
    color: #CCFBFF;
}
