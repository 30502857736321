@import "root";

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #298eb0;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #1F7998;
}


body {
    background: $second-color;
    font-family: $font-family;
}

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.each__page {
    padding-top: 88px;
    min-height: 80vh;
}

.p-0_5 {
    padding: 0.15rem !important
}

a:hover {
    color: lightseagreen;
    text-decoration: none;
}

input[type=text]:focus, input[type=email]:focus, input[type=password]:focus {
    outline: none;
}

.cart_div {
    position: relative;
}

.count_cart {
    position: absolute;
    width: 70%;
    height: 70%;
    background-color: $third-color;
    border-radius: 50%;
    left: 70%;
    top: -15%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.count_cart_web {
    position: absolute;
    width: 40%;
    height: 85%;
    border-radius: 50%;
    left: 10%;
    top: -40%;
    background-color: $third-color;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $fourth-color;
    font-family: sans-serif;
    font-size: 14px;
}

@media only screen and (max-width: 1056px) {
    .each__page {
        padding-top: 60px;
    }
}
