.lamborghini_huracan {
    transform: scale(3); /* temporary scale to view level of details */
    border: 1px solid white;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lamborghini_huracan .fil23 {
    fill: #FEFEFE
}

.lamborghini_huracan .fil39 {
    fill: #EBDDE2
}

.lamborghini_huracan .fil36 {
    fill: #E6E7E6
}

.lamborghini_huracan .fil45 {
    fill: #999999
}

.lamborghini_huracan .fil41 {
    fill: #616161
}

.lamborghini_huracan .window_glass {
    fill: var(--window-color);
    opacity: .5;
    transition: fill 1s;
}

/* window glass color */
.lamborghini_huracan .fil21 {
    fill: #282829
}

.lamborghini_huracan .fil1 {
    fill: #282829
}

.lamborghini_huracan .fil38 {
    fill: #292929
}

.lamborghini_huracan.fil28 {
    fill: black
}

.lamborghini_huracan .body_paint {
    fill: var(--body-color);
    transition: fill 1s;
}

/* car paint */
.lamborghini_huracan .fil37 {
    fill: #2A3539
}

.lamborghini_huracan .fil26 {
    fill: #3F5563
}

.lamborghini_huracan .fil0 {
    fill: transparent
}

.lamborghini_huracan .fil30 {
    fill: #BCC5CE
}

.lamborghini_huracan .fil29 {
    fill: white
}

.lamborghini_huracan .fil43 {
    fill: #FEFEFE;
    fill-rule: nonzero
}

.lamborghini_huracan .fil40 {
    fill: #282829;
    fill-rule: nonzero
}

.lamborghini_huracan .fil18 {
    fill: #040404;
    fill-rule: nonzero
}

.lamborghini_huracan .fil7 {
    fill: #282829;
    fill-rule: nonzero
}

.lamborghini_huracan .fil8 {
    fill: #292929;
    fill-rule: nonzero
}

.lamborghini_huracan .fil15 {
    fill: #350700;
    fill-rule: nonzero
}

.lamborghini_huracan .fil17 {
    fill: #373435;
    fill-rule: nonzero
}

.lamborghini_huracan .fil10 {
    fill: #3F4040;
    fill-rule: nonzero
}

.lamborghini_huracan .fil27 {
    fill: #607785;
    fill-rule: nonzero
}

.lamborghini_huracan .fil4 {
    fill: #616161;
    fill-rule: nonzero
}

.lamborghini_huracan .fil11 {
    fill: #745012;
    fill-rule: nonzero
}

.lamborghini_huracan .fil6 {
    fill: #81949B;
    fill-rule: nonzero
}

.lamborghini_huracan .fil3 {
    fill: #999999;
    fill-rule: nonzero
}

.lamborghini_huracan .fil42 {
    fill: #AEB7C0;
    fill-rule: nonzero
}

.lamborghini_huracan .fil13 {
    fill: #D19300;
    fill-rule: nonzero
}

.lamborghini_huracan .fil19 {
    fill: #DBDE35;
    fill-rule: nonzero
}

.lamborghini_huracan .fil12 {
    fill: #E4AA00;
    fill-rule: nonzero
}

.lamborghini_huracan .fil9 {
    fill: #E6E7E6;
    fill-rule: nonzero
}

.lamborghini_huracan .fil14 {
    fill: #F8C311;
    fill-rule: nonzero
}

.lamborghini_huracan .fil16 {
    fill: white;
    fill-rule: nonzero
}

.lamborghini_huracan .fil24 {
    fill: url(#id126)
}

.lamborghini_huracan .fil44 {
    fill: url(#id127)
}

.lamborghini_huracan .fil34 {
    fill: url(#id128)
}

.lamborghini_huracan .fil31 {
    fill: url(#id129)
}

.lamborghini_huracan .fil35 {
    fill: url(#id130)
}

.lamborghini_huracan .fil33 {
    fill: url(#id131)
}

.lamborghini_huracan .fil32 {
    fill: url(#id132)
}

.lamborghini_huracan .fil25 {
    fill: url(#id133);
    fill-rule: nonzero
}

.lamborghini_huracan .fil20 {
    fill: url(#id134);
    fill-rule: nonzero
}

.lamborghini_huracan .view_mirror {
    fill: var(--body-color);
    transition: fill 1s;
}

/* view mirror glass color */
.lamborghini_huracan .fil2 {
    fill: url(#id135);
    fill-rule: nonzero
}

.lamborghini_huracan .fil5 {
    fill: url(#id136);
    fill-rule: nonzero
}

.lamborghini_huracan #wheel_back #disk,
.lamborghini_huracan #wheel_back #pattern {
    transform-origin: 8.35px 9.9px;
    transform: rotate(30deg);
}

.lamborghini_huracan #wheel_front #disk,
.lamborghini_huracan #wheel_front #pattern {
    transform-origin: 39.61px 9.88px;
    transform: rotate(30deg);
}

.lamborghini_huracan #wheel_front #disk,
.lamborghini_huracan #wheel_front #pattern,
.lamborghini_huracan #wheel_back #disk,
.lamborghini_huracan #wheel_back #pattern {
    animation-name: wheel;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes wheel {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(800deg);
    }
}

@media screen and (max-width: 1056px) {
    .lamborghini_huracan{
       display: none;
    }
}
