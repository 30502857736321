@import "root";
/*Header and logo styling*/
.admin_header {
    position: absolute;
    width: 100%;
    z-index: 3;
    height: 46px;
    background-color: #fff;
    border-bottom: 1px solid $second-color;
}

/* logo in header(mobile version) and side-nav (tablet & desktop) */
.admin_logo {
    height: 44px;
    padding: 10px;
    font-weight: 700;
    color: $third-color;
}

.admin_wrapper {
    td, tr, th{
        border-color: $second-color;
    }
}

.admin_header .admin_logo {
    color: #233245;
}

.admin_side-nav .admin_logo {
    background-color: $first-color;
    color: #fff;
}

.admin_header .admin_logo {
    float: left;
}

.admin_header .admin_logo {
    height: 44px;
    z-index: 1;
    padding: 10px;
    font-weight: 700;
    background-color: $first-color;
}

.admin_logo i {
    font-size: 22px;
}

.admin_logo span {
    margin-left: 5px;
    text-transform: uppercase;
}

.admin_nav-trigger {
    position: relative;
    float: right;
    width: 20px;
    height: 44px;
    right: 30px;
    display: block;
}

.admin_nav-trigger span, .admin_nav-trigger span:before, span:after {
    width: 20px;
    height: 2px;
    background-color: $first-color;
    position: absolute;
}

.admin_nav-trigger span {
    top: 50%;
}

.admin_nav-trigger span:before, .admin_nav-trigger span:after {
    content: '';
}

.admin_nav-trigger span:before {
    top: -6px;
}

.admin_nav-trigger span:after {
    top: 6px;
}

/* style for side navigation */
.admin_side-nav {
    position: absolute;
    width: 220px;
    height: 100vh;
    background-color: $second-color;
    z-index: 1;
    display: none;
}

.admin_side-nav.visible {
    display: block;
}

.admin_side-nav ul {
    margin: 0;
    padding: 0;
}

.admin_side-nav ul li {
    padding: 16px 16px;
    border-bottom: 1px solid $first-color;
    position: relative;
}

.admin_side-nav ul li.active:before {
    content: '';
    position: absolute;
    width: 4px;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #eaedf3;
}

.admin_active_a {
    background: #302d43;
    color: #fff;
    border-right: 5px solid $third-color;
    padding: 5px;
    transition: all .25s ease-in-out;
}

.admin_wrapper {
    background-color: $fourth-color;
}

.admin_wrapper > .container {
    //overflow: hidden;
    height: 90vh;
}

.admin_side-nav ul li a {
    color: #fff;
    display: block;
    text-decoration: none;
    transition: all .25s ease-in-out;

}

.admin_side-nav ul li a.router-link-active,
.admin_side-nav ul li a.router-link-exact-active {
    background: #302d43;
    color: #fff;
    border-right: 5px solid $third-color;
    padding: 5px;
}

/*hover over*/
.admin_side-nav ul li a:hover {
    background: #302d43;
    color: #fff;
    border-right: 5px solid $third-color;
    padding: 5px;
    transition: all .25s ease-in-out;
}

.admin_side-nav ul li i {
    color: $third-color;
    min-width: 20px;
    text-align: center;
}

.admin_side-nav ul li span:nth-child(2) {
    margin-left: 10px;
    font-size: 12px;
    font-weight: 580;
}

/* main content styles */
.admin_main-content {
    padding: 43px;
    margin-top: 0;
    padding: 0;
    padding-top: 46px;
    height: 100%;
    overflow: scroll;
}

.admin_main-content .title {
    background-color: #eef1f7;
    border-bottom: 2px solid $second-color;
    padding: 12px 470px;
    font-weight: 700;
    color: #333;
    font-size: 18px;
}

.admin_wrapper {
    padding-left: 200px;
    height: 100vh;
    padding-top: 50px;
    overflow-y: scroll;
}

.admin_table {
    height: auto;
    overflow-x: auto;
}

.admin_th {
    text-align: center;
}

.admin_td {
    text-align: center;
    font-family: sans-serif;
    img {
        width: 80px;
        height: 80px;
    }
}

@media screen and (min-width: 600px) {
    .admin_header {
        background-color: $second-color;
        z-index: 1;
    }
    .admin_header .logo {
        display: none;
    }
    .admin_nav-trigger {
        display: none;
    }
    .admin_nav-trigger span, .admin_nav-trigger span:before, span:after {
        background-color: #fff;
    }
    .admin_side-nav {
        display: block;
        width: 70px;
        z-index: 2;
    }
    .admin_side-nav ul li span:nth-child(2) {
        display: none;
    }
    .admin_side-nav .logo i {
        padding-left: 12px;
    }
    .admin_side-nav .logo span {
        display: none;
    }
    .admin_side-nav ul li i {
        font-size: 25px;
    }
    .admin_side-nav ul li a {
        text-align: center;
    }
    .admin_main-content {
        margin-left: 70px;
    }
}

/* set element styles for desktop */
@media screen and (min-width: 800px) {
    .admin_side-nav {
        width: 200px;
    }
    .admin_side-nav ul li span:nth-child(2) {
        display: inline-block;
    }
    .admin_side-nav ul li i {
        font-size: 16px;
    }
    .admin_side-nav ul li a {
        text-align: left;
    }
    .admin_side-nav .logo i {
        padding-left: 0;
    }
    .admin_side-nav .logo span {
        display: inline-block;
    }
    .admin_main-content {
        margin-left: 200px;
    }
}

/* main content in box container */
.admin_main {
    display: flex;
    flex-flow: row wrap;
}

.admin_widget {
    flex-basis: 300px;
    flex-grow: 10;
    height: 400px;
    margin: 15px;
    border-radius: 6px;
    background-color: #ffffff;
    position: relative;
}

.admin_widget .title {
    background-color: #eef1f7;
    border-bottom: 1px solid $second-color;
    padding: 10px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    color: #617085;
    font-weight: 600;
}

@media only screen and (max-width: 768px) {
    .admin_wrapper {
        padding-left: 20px;
        padding-top: 50px;
    }
}
