
@import "root";

.anim {
    animation-name: heartbeat;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    display: inline-block;
    color: #c20e19;
}

@keyframes heartbeat {
    0% {
        transform: scale(.75);
    }
    20% {
        transform: scale(1);
    }
    40% {
        transform: scale(.75);
    }
    60% {
        transform: scale(1);
    }
    80% {
        transform: scale(.75);
    }
    100% {
        transform: scale(.75);
    }
}

.footer-distributed{
    background: $first-color;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    font: bold 16px sans-serif;
    padding: 55px 50px;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right{
    display: inline-block;
    vertical-align: top;
}

/* Footer left */

.footer-distributed .footer-left{
    width: 40%;
}

/* The company logo */

.footer-distributed h3{
    color:  $fourth-color;
    font: normal 36px 'Open Sans', cursive;
    margin: 0;
}

.footer-distributed h3 span{
    color:  $third-color;
}

/* Footer links */

.footer-distributed .footer-links{
    color:  $fourth-color;
    margin: 20px 0 12px;
    padding: 0;
}

.footer-distributed .footer-links a{
    display:inline-block;
    line-height: 1.8;
    font-weight:400;
    text-decoration: none;
    color:  inherit;
}

.footer-distributed .footer-company-name{
    color:  $fourth-color;
    font-size: 14px;
    font-weight: normal;
    margin: 0;
}

/* Footer Center */

.footer-distributed .footer-center{
    width: 35%;
}

.footer-distributed .footer-center i{
    background-color:  $second-color;
    color: $fourth-color;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    text-align: center;
    margin: 10px 15px;
    vertical-align: middle;
    font-size: 17px;
    line-height: 38px;
}

.footer-distributed .footer-center p {
    display: inline-block;
    color: $fourth-color;
    font-weight:400;
    vertical-align: middle;
    margin:0;
}

.footer-distributed .footer-center a {
    display: inline-block;
    color: $fourth-color;
    font-weight:400;
    vertical-align: middle;
    text-decoration: none;
    margin:0;
}

.footer-distributed .footer-center a span{
    display:block;
    font-weight: normal;
    font-size:14px;
    line-height:2;
}

.footer-distributed .footer-center p span{
    display:block;
    font-weight: normal;
    font-size:14px;
    line-height:2;
}

.footer-distributed .footer-center p a{
    color:  $third-color;
    text-decoration: none;;
}

.footer-distributed .footer-links a:before {
    content: "|";
    font-weight:300;
    font-size: 20px;
    left: 0;
    color: $fourth-color;
    display: inline-block;
    padding-right: 5px;
}

.footer-distributed .footer-links .link-1:before {
    content: none;
}

/* Footer Right */

.footer-distributed .footer-right{
    width: 20%;
}

.footer-distributed .footer-company-about{
    line-height: 20px;
    color: $fourth-color;
    font-size: 13px;
    font-weight: normal;
    margin: 0;
}

.footer-distributed .footer-company-about span{
    display: block;
    color:  $fourth-color;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
}

.footer-distributed .footer-icons{
    margin-top: 25px;
}

.footer-distributed .footer-icons a{
    display: inline-block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-color:  $first-color;
    border-radius: 2px;

    font-size: 20px;
    color: $fourth-color;
    text-align: center;
    line-height: 35px;

    margin-right: 3px;
    margin-bottom: 5px;
}

/* If you don't want the footer to be responsive, remove these media queries */

@media (max-width: 880px) {

    .footer-distributed{
        font: bold 14px sans-serif;
    }

    .footer-distributed .footer-left,
    .footer-distributed .footer-center,
    .footer-distributed .footer-right{
        display: block;
        width: 100%;
        margin-bottom: 40px;
        text-align: center;
        h3{
            font-size: 24px;
        }
    }

    .footer-distributed .footer-center i{
        margin-left: 0;
    }

}
