@import "root";

.error-alert {
    padding: 10px;
    color: #D8000C;
    background-color: $error-color;
    margin: 10px 0;
    border-radius: 3px 3px 3px 3px;
}

.auth_container{
    margin: 20px auto;
}
.form-container > form > h1{
    font-size: 34px;
    font-family: $font-family;
}

.overlay-panel>h1{
    font-size: 44px;
    font-family: $font-family;
}

.overlay-panel>p {
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 20px 0 30px;
    font-family: $font-family;
}


.sign-in-container>form>a {
    color: #333;
    font-size: 14px;
    text-decoration: none;
    margin: 15px 0;
    font-family: $font-family;
}

.buttonchik {
    border-radius: 20px;
    border: 1px solid $third-color;
    background-color: $third-color;
    color: $fourth-color;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 25px;
    margin-top: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
    font-family: $font-family;
    cursor: pointer;
}

.buttonchik:active {
    transform: scale(0.95);
}

.buttonchik:focus {
    outline: none;
}

.buttonchik.ghost {
    background-color: transparent;
    border-color: $fourth-color;
}
.buttonchik.ghost:hover {
    color: $fourth-color;
}
.form-container > form {
    background-color: $fourth-color;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px;
    height: 100%;
    text-align: center;
}

.form-container > form >input {
    background-color: $fourth-color;
    border: 1px solid $third-color;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
    font-family: $font-family
}

.auth_container > .container {
    background-color: $fourth-color;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25),
    0 10px 10px rgba(0,0,0,0.22);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 480px;
}

.form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

.sign-in-container {
    left: 0;
    width: 50%;
    z-index: 2;
}

.container.right-panel-active .sign-in-container {
    transform: translateX(100%);
}

.sign-up-container {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
}

.container.right-panel-active .sign-up-container {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: show 0.6s;
}

@keyframes show {
    0%, 49.99% {
        opacity: 0;
        z-index: 1;
    }

    50%, 100% {
        opacity: 1;
        z-index: 5;
    }
}

.overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
}

.container.right-panel-active .overlay-container{
    transform: translateX(-100%);
}

.overlay {
    background: $third-color;
    background: -webkit-linear-gradient(to right, $third-color, rgb(34, 201, 192));
    background: linear-gradient(to right, $third-color, rgb(34, 201, 192));
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: $fourth-color;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
    transform: translateX(50%);
}

.overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.overlay-left {
    transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
    transform: translateX(0);
}

.overlay-right {
    right: 0;
    transform: translateX(0);
}

.container.right-panel-active .overlay-right {
    transform: translateX(20%);
}

.social-container {
    margin: 20px 0;
}

.social-container a {
    border: 1px solid $fourth-color;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    height: 40px;
    width: 40px;
}

@media only screen and (max-width:767px){
    .auth_container{
        padding: 0 10px;
    }
    .form-container > form {
        padding: 5px;
    }
    .overlay-panel>h1 {
        font-size: 28px;
    }
}

