@import "root";

.stock__block {
    width: 87%;
    margin: 150px auto;
}

.stock__title {
    display: flex;
    justify-content: center;
}

.stock {
    width: 257px;
    height: 126px;
    background: #CAD011;
    border: 1px solid #E7DADA;
    box-sizing: border-box;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding-top: 25px;
    position: relative;
}

.stock__title p {
    font-family: $font-family;
    font-size: 52px;
    line-height: 80px;
    color: $third-color;
}

.stock_shadow {
    top: -15px;
    right: -20px;
    position: absolute;
    z-index: -5555;
    width: 257px;
    height: 126px;
    background: rgba(239, 113, 96, 0.79);
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
}

.stock__items {
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
    gap: 35px;
    flex-wrap: wrap;
}

.stock__items-container {
    display: flex;
    justify-content:space-around;
}

