.product__card__admin{
    height: 430px;
    background: #222831;
    box-shadow: 10px 10px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px 20px;
    padding: 10px;
}

.product_presentation_admin{
    display: flex;
    justify-content: center;
}

.product_img_admin{
    width: 90%;
    height: 250px;
    background-color: #fff;
    overflow: hidden;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;

    &:hover{
        opacity: 0.7;
    }
}
.product_img_admin>img {
    width: 70px;
    height: 70px;
}
