
@import "root";

.laboratory {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.laboratory > div {
    width: 45%;
}

.laboratory__title {
    font-family: $font-family;
    font-size: 64px;
    line-height: 83px;
    text-transform: uppercase;
    color: $fourth-color;
    text-shadow: 2px -6px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 35px;
}
.laboratory__title span {
    color: $third-color;
    font-family: $font-family;
}

.laboratory__description {
    font-family: $font-family;
    font-size: 24px;
    line-height: 31px;
    color: $fourth-color;
    width: 77%;
    text-align: left;
}

.pink_circle {
    width: 528px;
    height: 528px;
    background: $third-color;
    box-shadow: 9px 0 0 rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1060px) {
    .laboratory {
        flex-direction: column;
        justify-content: center;
    }
    .laboratory .sec {
        margin: 20px auto;
        width: 90%;
        text-align: justify;
    }
    .laboratory__description {
        width: 95%;
    }
    .one {
        margin-left: 20%;
        order: 1;
        display: flex;
        justify-content: center;
    }

    .sec {
        order: 2;
    }

    .pink_circle {
        width: 340px;
        height: 340px;
    }
}

@media only screen and (max-width: 760px) {
    .pink_circle {
        width: 200px;
        height: 200px;
    }

    .pink_circle img {
        width: 50px;
    }
    .laboratory > div {
        width: 60%;
    }
    .one{
        margin-left: 0;
    }
    .laboratory__description {
        width: 90%;
        margin: auto;
        font-size: 20px;
        line-height: 40px;
    }
}

@media only screen and (max-width: 650px) {
    .laboratory__title {
        font-size: 37px;
    }

}
