@import 'root';

.contact_us_map {
    position: relative;
    margin-left: 10%;
    margin-bottom: 50px;
    border-radius: 20px;
    border: none;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
}

.contact_us_background {
    display: flex;
    /* min-height: 100vh; */
}

.contact_us_container {
    flex: 0 1 700px;
    margin: 20px auto 70px;
    padding: 10px;
}

.contact_us_screen {
    position: relative;
    background: $first-color;
    border-radius: 15px;
}

.contact_us_screen:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 20px;
    right: 20px;
    bottom: 0;
    border-radius: 15px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
    z-index: -1;
}

.contact_us_screen-header {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background: $first-color;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.contact_us_screen-header-left {
    margin-right: auto;
}

.contact_us_screen-header-button {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 3px;
    border-radius: 8px;
    background: $fourth-color;
}

.contact_us_screen-header-button.close_contact {
    background: $third-color;
}

.contact_us_screen-header-button.maximize_contact {
    background: $second-color;
}

.contact_us_screen-header-button.minimize_contact {
    background: $fourth-color;
}

.contact_us_screen-header-right {
    display: flex;
}

.contact_us_screen-header-ellipsis {
    width: 3px;
    height: 3px;
    margin-left: 2px;
    border-radius: 8px;
    background: $first-color;
}

.contact_us_screen-body {
    display: flex;
}

.contact_us_screen-body-item {
    flex: 1;
    padding: 20px 50px;
}

.contact_us_screen-body-item.left {
    display: flex;
    flex-direction: column;
}

.contact_us_app-title {
    display: flex;
    flex-direction: column;
    position: relative;
    color: $third-color;
    font-size: 24px;
    margin-bottom: 10px;
}

.contact_us_app-title-right {
    display: flex;
    flex-direction: column;
    position: relative;
    color: $third-color;
    font-size: 24px;
    margin-bottom: 30px;
}

.contact_us_footer-icons {
    margin-top: 25px;
    display: flex;
    justify-content: center;
}

.contact_us_footer-icons a {
    display: inline-block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-color: $second-color;
    border-radius: 2px;

    font-size: 20px;
    color: $fourth-color;
    text-align: center;
    line-height: 35px;

    margin-right: 3px;
    margin-bottom: 5px;
}

.contact_us_app-title:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 25px;
    height: 4px;
    background: $third-color;
}

.contact_us_app-title-right:after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    bottom: -10px;
    width: 100%;
    height: 4px;
    background: $third-color;
}

.contact_us_footer-center {
    padding-top: 30px;
    width: 100%;
}

.contact_us_footer-center i {
    background-color: #33383b;
    color: $third-color;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    text-align: center;
    margin: 10px 15px;
    vertical-align: middle;
    font-size: 17px;
    line-height: 38px;
}

.contact_us_footer-center p {
    display: inline-block;
    color: $fourth-color;
    font-weight: 400;
    vertical-align: middle;
    margin: 0;
}

.contact_us_footer-center a {
    display: inline-block;
    color: $fourth-color;
    font-weight: 400;
    vertical-align: middle;
    text-decoration: none;
    margin: 0;
}

.contact_us_footer-center a span {
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 2;
}

.contact_us_footer-center p span {
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 2;
}

.contact_us_app-form-group {
    margin-bottom: 15px;
}

.contact_us_app-form-group.message {
    margin-top: 40px;
}

.contact_us_app-form-group.buttons {
    margin-bottom: 0;
    text-align: right;
}

.contact_us_app-form-control {
    width: 100%;
    padding: 10px 0;
    background: none;
    border: none;
    border-bottom: 1px solid $second-color;
    color: #ddd;
    font-size: 14px;
    text-transform: none;
    outline: none;
    transition: border-color 0.2s;
}

.contact_us_app-form-control::placeholder {
    color: #ddd;
}

.contact_us_app-form-control:focus {
    border-bottom-color: $fourth-color;
}

.contact_us_app-form-button {
    background: none;
    border: none;
    color: $third-color;
    font-size: 14px;
    cursor: pointer;
    outline: none;
}

.contact_us_app-form-button:hover {
    color: $third-color;
}

.contact_us_credits {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    color: #ffa4bd;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 16px;
    font-weight: normal;
}

.contact_us_credits-link {
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: bold;
    text-decoration: none;
}

.contact_us_dribbble {
    width: 20px;
    height: 20px;
    margin: 0 5px;
}

@media screen and (max-width: 520px) {
    .contact_us_screen-body {
        flex-direction: column;
    }

    .contact_us_screen-body-item.left {
        margin-bottom: 30px;
    }

    .contact_us_app-title {
        flex-direction: row;
    }

    .contact_us_app-title span {
        margin-right: 12px;
    }

    .contact_us_app-title:after {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .contact_us_screen-body {
        padding: 40px;
    }

    .contact_us_screen-body-item {
        padding: 0;
    }
}
