
@import "root";

.partner__block {
    height: 609px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.block__present {
    padding-top: 45px;
    padding-bottom: 45px;
    height: 362px;
    width: 60%;
    background: $first-color;
    border:5px solid $third-color;
    box-sizing: border-box;
    box-shadow: 12px 5px 12px 12px rgba(0, 0, 0, 0.25);
    border-radius: 20px 0;
    color: $fourth-color;

}

.partners__title p {
    font-size: 28px;
    line-height: 48px;
    text-align: center;
}

.partners__logo_present {
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.partners__logo_present img{
    width: 200px;
}

@keyframes border-flicker {
    0% {
        opacity:0.1;
        -webkit-box-shadow: 0px 0px 78px 4px rgba(16,134,232,0.73);
        -moz-box-shadow: 0px 0px 78px 4px rgba(16,134,232,0.73);
        box-shadow: 0px 0px 78px 4px rgba(16,134,232,0.73);
    }
    2% {
        opacity:1;
        -webkit-box-shadow: 0px 0px 78px 4px rgba(16,134,232,0.73);
        -moz-box-shadow: 0px 0px 78px 4px rgba(16,134,232,0.73);
        box-shadow: 0px 0px 78px 4px rgba(16,134,232,0.73);
    }
    4% {
        opacity:0.1;
        -webkit-box-shadow: 0px 0px 78px 4px rgba(16,134,232,0.73);
        -moz-box-shadow: 0px 0px 78px 4px rgba(16,134,232,0.73);
        box-shadow: 0px 0px 78px 4px rgba(16,134,232,0.73);
    }

    8% {
        opacity:1;
        -webkit-box-shadow: 0px 0px 78px 4px rgba(16,134,232,0.73);
        -moz-box-shadow: 0px 0px 78px 4px rgba(16,134,232,0.73);
        box-shadow: 0px 0px 78px 4px rgba(16,134,232,0.73);
    }
    70% {
        opacity:0.7;
        -webkit-box-shadow: 0px 0px 78px 4px rgba(16,134,232,0.73);
        -moz-box-shadow: 0px 0px 78px 4px rgba(16,134,232,0.73);
        box-shadow: 0px 0px 78px 4px rgba(16,134,232,0.73);
    }
    100% {
        opacity:1;
        -webkit-box-shadow: 0px 0px 78px 4px rgba(16,134,232,0.73);
        -moz-box-shadow: 0px 0px 78px 4px rgba(16,134,232,0.73);
        box-shadow: 0px 0px 78px 4px rgba(16,134,232,0.73);
    }
}

@keyframes letter-flicker {
    0% {
        opacity:0.1;
        text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
    }
    2% {
        opacity:0.1;
        text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
    }
    4% {
        opacity:1;
        text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
    }


    19% {
        opacity:1;
        text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
    }
    21% {
        opacity:0.1;
        text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
    }
    23% {
        opacity:1;
        text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
    }

    80% {
        opacity:1;
        text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
    }
    83% {
        opacity:0.4;
        text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
    }
    87% {
        opacity:1;
        text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
    }
}


@media only screen and (max-width: 1558px) {
    .block__present {
        width: 90%;
    }
}

@media only screen and (max-width: 1155px) {
    .block__present {
        width: 80%;
    }
}

@media only screen and (max-width: 1056px) {
    .block__present {
        width: 100%;
        height: 100%;
    }

    .partner__block {
        height: 400px;
    }
}

@media only screen and (max-width: 767px) {
    .partner__block {
        height: 350px !important;
    }
    .partners__title p {
        font-size: 22px;
        line-height: 40px;
        text-align: center;
    }
}

@media only screen and (max-width: 560px) {
    .partner__block {
        height: 400px !important;
    }
}
