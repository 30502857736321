@import "root";

.car-image{
    width: 100%;
    height: 100vh;
}

.third-color {
    color: $third-color;;
}

.fourth-color {
    color: $fourth-color;
}

.car_color {
    display: flex;
    justify-content: center;

    > div {
        width: 50%;
        height: 800px;
        background-color: black;
    }
}

.color__background {
    height: 100%;
    width: 100%;
    z-index: 0;
}

.color_content {
    position: absolute;
    width: 50%;
    height: 100vh;
    left: 50%;
    z-index: 10;
    overflow: hidden;
}

.car_img {
    width: 50%;
    > img {
        width: 100%;
        height: 100%;
    }
}

.color__title {
    margin-top: 30px;
    text-align: center;

    > p, > span {
        font-family: $font-family;
        font-size: 30px;
        line-height: 10rem;
    }
}

.color__description {
    width: 90%;
    margin: auto;

    > p {
        font-family: $font-family;
        font-size: 1rem;
        line-height: 2rem;
        color: $fourth-color;
    }
}

@media only screen and (max-width: 1350px) {
    .color__title {
        width: 100%;
    }
    .color__description > p {
        font-size: 20px;
        line-height: 40px;
    }
}

@media only screen and (max-width: 1056px) {

    .color_content {
        margin-bottom: 120px;
    }

    .car_color > div {
        background-color: #03080b;
    }

    .color__title {
        font-size: 39px;
    }

    .car_color > div {
        height: 575px;
        background-size: cover;
    }
    .color__description > p {
        font-size: 14px;
    }

}

@media only screen and (max-width: 767px) {

    .car-image{
        height: 50vh;
    }

    .color_content {
        width: 50%;
        margin: 0 auto 120px;
    }
    //.car_color > div {
    //    height: 300px;
    //}
    .color__title {
        margin-top: 50%;
        line-height: 50px;
        font-size: 26px;
    }
    .color__title > p, .color__title > span {
        font-size: 26px;
        line-height: 52px;
    }
    .color__description > p {
        display: none;
    }
    .block__present img {
        width: 100px !important;
    }
    .partner__block {
        height: 500px !important;
    }

}

@media only screen and (max-width: 380px) {
    .color__title > p, .color__title > span {
        font-size: 20px;
        line-height: 52px;
    }
}
