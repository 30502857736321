
@import "root";

.about_component {
    width: 80%;
    margin: 50px auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.about_component > div {
    width: 45%;
    height: 600px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about_component_description,
.about_component_car_image-circle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.about_component_car_image-circle {
    width: 500px;
    height: 500px;
    background: $fourth-color;
    border: 5px solid $third-color;
    border-radius: 50%;
}

.about_component_description-title {
    font-family: $font-family;
}

.about_component_description-title span {
    font-family: $font-family;
    color: $fourth-color;
}

.about_component_description-title {
    font-family: $font-family;
    font-size: 64px;
    line-height: 83px;
    color: $third-color;
}

.about_component_description-text {
    margin-top: 20px;
    font-family: $font-family;
    font-size: 20px;
    line-height: 38px;
    color: $fourth-color;
}

.btn-container {
    margin-top: 47px;
    font-family: $font-family;
    display: flex;
    justify-content: left;
}

@media only screen and (max-width: 1580px) {
    .about_component > div {
        width: 50%;
    }
}

@media only screen and (max-width: 1281px) {
    .about_component_car_image-circle {
        width: 500px;
        height: 500px;
    }
}

@media only screen and (max-width: 1056px) {
    .about_component {
        flex-direction: column;
        margin-top: 70px;
    }

    .about_component_car_image {
        height: 50px !important;
        display: flex;
        justify-content: center;
        gap: 40px;
    }

    .about_component_car_image-circle {
        width: 163px;
        height: 163px;
    }

    .about_component_description-title {
        display: none;
    }

    .about_component_description {
        align-items: flex-start;
        height: auto !important;
    }

    .about_component > div {
        width: 100%;
        margin-top: 30px;

    }
}
