
@import "root";

.catalog_block{
    margin-top: 46px;
    h1{
        font-size: 50px;
    }
}

.catalogs {
    background-color: $first-color;
    width: 100%;
    height: auto;
    padding: 20px 50px;
    border-radius: 8px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.catalog_type {
    height: 60px;
    border-radius: 8px;
    margin: 10px 0;
}

.b-dropdown {
    width: 100%;
    height: 100%;
}

.b-dropdown > button {
    background-color: $third-color;
    color: $fourth-color;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.b-dropdown > button:active {
    background-color: $third-color;
}

.b-dropdown > button:hover, .b-dropdown > button:focus {
    background-color: $second-color;;
}

.b-dropdown > ul {
    width: 100%;
    background-color: #045470;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.b-dropdown > ul > li > button {
    width: 100%;
    color: white;
    background-color: #045470;
}

.b-dropdown > ul > li > button:hover {
    background-color: #009AAF;
    color: white;
}

.catalog_type > button {
    background-color: $third-color;
    color: $fourth-color;
    font-family: sans-serif;
    height: 100%;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.catalog_type > button:active {
    background-color: $third-color;
}

.catalog_type > button:hover, .b-button > button:focus {
    background-color: $second-color;;
}

.catalog_type > ul {
    width: 100%;
    background-color: #045470;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.catalog_type > ul > li > button {
    width: 100%;
    color: white;
    background-color: #045470;
}

.catalog_type > ul > li > button:hover {
    background-color: #009AAF;
    color: white;
}

@media (max-width: 1056px) {
    .catalogs {
        padding: 20px 0px;
    }
}
@media only screen and (max-width: 576px) {
    .catalog_type button{
        font-size: 12px;
    }
}

