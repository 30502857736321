@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;1,300&display=swap");
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}
.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid, .form-control.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #71dd8a;
}
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid ~ .custom-file-label::after, .custom-file-input.is-valid ~ .custom-file-label::after {
  border-color: inherit;
}
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #efa2a9;
}
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid ~ .custom-file-label::after, .custom-file-input.is-invalid ~ .custom-file-label::after {
  border-color: inherit;
}
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover, .btn:focus {
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 0 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
}
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  user-select: none;
  background-color: #dee2e6;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(128, 189, 255, 0.5);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input:focus ~ .custom-file-label::after {
  border-color: #80bdff;
}
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.25rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content .tab-pane {
  display: none;
}
.tab-content .tab-pane.active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}
.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-top,
.card-group > .card:first-child .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-bottom,
.card-group > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-top,
.card-group > .card:last-child .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-bottom,
.card-group > .card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }
  .card-group > .card:only-child {
    border-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-top,
.card-group > .card:only-child .card-header {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-bottom,
.card-group > .card:only-child .card-footer {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
.card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
.card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
.card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}
.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}
.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}
.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
.badge-success[href]:hover, .badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
.badge-info[href]:hover, .badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
.badge-warning[href]:hover, .badge-warning[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
.badge-danger[href]:hover, .badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
.badge-light[href]:hover, .badge-light[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
.badge-dark[href]:hover, .badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.list-group-item:hover, .list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}
@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (0.5rem * 2));
  content: "";
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top .arrow, .bs-popover-auto[x-placement^=top] .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^=top] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^=top] .arrow::after {
  border-width: 0.5rem 0.5rem 0;
}
.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^=top] .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top .arrow::after, .bs-popover-auto[x-placement^=top] .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right .arrow, .bs-popover-auto[x-placement^=right] .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^=right] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^=right] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}
.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^=right] .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right .arrow::after, .bs-popover-auto[x-placement^=right] .arrow::after {
  left: 1px;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^=bottom] .arrow {
  top: calc((0.5rem + 1px) * -1);
}
.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^=bottom] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}
.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^=bottom] .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^=bottom] .arrow::after {
  top: 1px;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left .arrow, .bs-popover-auto[x-placement^=left] .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^=left] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^=left] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}
.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^=left] .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left .arrow::after, .bs-popover-auto[x-placement^=left] .arrow::after {
  right: 1px;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  backface-visibility: hidden;
  perspective: 1000px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
  transition: transform 0.6s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
    transition: none;
  }
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0);
}
@supports (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%);
}
@supports (transform-style: preserve-3d) {
  .carousel-item-next,
.active.carousel-item-right {
    transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%);
}
@supports (transform-style: preserve-3d) {
  .carousel-item-prev,
.active.carousel-item-left {
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: 0.6s;
  transition-property: opacity;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0;
}
.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  transform: translateX(0);
}
@supports (transform-style: preserve-3d) {
  .carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  position: relative;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
}
.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}
.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}
.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed, .headroom--unpinned, .headroom--pinned {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
@-ms-viewport {
  width: device-width;
}
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
}

iframe {
  border: 0;
}

figcaption,
figure,
main {
  display: block;
}

main {
  overflow: hidden;
}

.section-nucleo-icons .icons-container {
  position: relative;
  max-width: 100%;
  height: 360px;
  margin: 0 auto;
  z-index: 1;
}

.section-nucleo-icons {
  --icon-size: 5rem;
  --icon-sm-size: 3.75rem;
  --gutter: 7rem;
}
.section-nucleo-icons .icons-container i {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #fff;
  z-index: 1;
  transform: translate(-50%, -50%);
  transition: all 0.2s cubic-bezier(0.25, 0.65, 0.9, 0.75);
}
.section-nucleo-icons .icons-container i.icon {
  width: var(--icon-size);
  height: var(--icon-size);
  font-size: 1.7em;
}
.section-nucleo-icons .icons-container i.icon-sm {
  width: var(--icon-sm-size);
  height: var(--icon-sm-size);
  font-size: 1.5em;
}
.section-nucleo-icons .icons-container i:nth-child(1) {
  font-size: 42px;
  color: #ffc107;
  z-index: 2;
}
.section-nucleo-icons .icons-container:not(.on-screen) i {
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
.section-nucleo-icons .icons-container:not(.on-screen) i:not(:nth-child(1)) {
  opacity: 0;
}
.section-nucleo-icons .icons-container.on-screen i {
  opacity: 1;
}
.section-nucleo-icons .icons-container.on-screen i:nth-child(1) {
  left: 50%;
  top: 50%;
  font-size: 42px;
  color: #ffc107;
}
.section-nucleo-icons .icons-container.on-screen i:nth-child(2) {
  left: calc(50% + var(--gutter) * 1.7);
  top: 50%;
}
.section-nucleo-icons .icons-container.on-screen i:nth-child(3) {
  left: calc(50% + var(--gutter));
  top: calc(50% + var(--gutter));
}
.section-nucleo-icons .icons-container.on-screen i:nth-child(4) {
  left: calc(50% + var(--gutter));
  top: calc(50% - var(--gutter));
}
.section-nucleo-icons .icons-container.on-screen i:nth-child(5) {
  left: calc(50% + var(--gutter) * 4);
  top: 50%;
}
.section-nucleo-icons .icons-container.on-screen i:nth-child(6) {
  left: calc(50% + var(--gutter) * 2.7);
  top: calc(50% + var(--gutter) * 1.5);
}
.section-nucleo-icons .icons-container.on-screen i:nth-child(7) {
  left: calc(50% + var(--gutter) * 2.7);
  top: calc(50% - var(--gutter) * 1.5);
}
.section-nucleo-icons .icons-container.on-screen i:nth-child(8) {
  left: calc(50% - var(--gutter) * 1.7);
  top: 50%;
}
.section-nucleo-icons .icons-container.on-screen i:nth-child(9) {
  left: calc(50% - var(--gutter));
  top: calc(50% + var(--gutter));
}
.section-nucleo-icons .icons-container.on-screen i:nth-child(10) {
  left: calc(50% - var(--gutter));
  top: calc(50% - var(--gutter));
}
.section-nucleo-icons .icons-container.on-screen i:nth-child(11) {
  left: calc(50% - var(--gutter) * 4);
  top: 50%;
}
.section-nucleo-icons .icons-container.on-screen i:nth-child(12) {
  left: calc(50% - var(--gutter) * 2.7);
  top: calc(50% + var(--gutter) * 1.5);
}
.section-nucleo-icons .icons-container.on-screen i:nth-child(13) {
  left: calc(50% - var(--gutter) * 2.7);
  top: calc(50% - var(--gutter) * 1.5);
}

.blur--hover {
  position: relative;
}
.blur--hover .blur-item {
  transition: 1s cubic-bezier(0.19, 1, 0.22, 1);
  will-change: transform;
  filter: blur(0);
  opacity: 1;
}
.blur--hover .blur-hidden {
  position: absolute;
  top: calc(50% + 7px);
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.2s ease-in-out;
  z-index: 100;
}
.blur--hover:hover .blur-item {
  opacity: 0.8;
  filter: blur(10px);
  transform: scale(0.95);
  z-index: 1;
}
.blur--hover:hover .blur-hidden {
  opacity: 1;
  top: 50%;
}

.bg-blue {
  background-color: #007bff !important;
}

a.bg-blue:hover, a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #0062cc !important;
}

.bg-indigo {
  background-color: #6610f2 !important;
}

a.bg-indigo:hover, a.bg-indigo:focus,
button.bg-indigo:hover,
button.bg-indigo:focus {
  background-color: #510bc4 !important;
}

.bg-purple {
  background-color: #6f42c1 !important;
}

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #59339d !important;
}

.bg-pink {
  background-color: #e83e8c !important;
}

a.bg-pink:hover, a.bg-pink:focus,
button.bg-pink:hover,
button.bg-pink:focus {
  background-color: #d91a72 !important;
}

.bg-red {
  background-color: #dc3545 !important;
}

a.bg-red:hover, a.bg-red:focus,
button.bg-red:hover,
button.bg-red:focus {
  background-color: #bd2130 !important;
}

.bg-orange {
  background-color: #fd7e14 !important;
}

a.bg-orange:hover, a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
  background-color: #dc6502 !important;
}

.bg-yellow {
  background-color: #ffc107 !important;
}

a.bg-yellow:hover, a.bg-yellow:focus,
button.bg-yellow:hover,
button.bg-yellow:focus {
  background-color: #d39e00 !important;
}

.bg-green {
  background-color: #28a745 !important;
}

a.bg-green:hover, a.bg-green:focus,
button.bg-green:hover,
button.bg-green:focus {
  background-color: #1e7e34 !important;
}

.bg-teal {
  background-color: #20c997 !important;
}

a.bg-teal:hover, a.bg-teal:focus,
button.bg-teal:hover,
button.bg-teal:focus {
  background-color: #199d76 !important;
}

.bg-cyan {
  background-color: #17a2b8 !important;
}

a.bg-cyan:hover, a.bg-cyan:focus,
button.bg-cyan:hover,
button.bg-cyan:focus {
  background-color: #117a8b !important;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-gray {
  background-color: #6c757d !important;
}

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #545b62 !important;
}

.bg-light {
  background-color: #ced4da !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #b1bbc4 !important;
}

.bg-lighter {
  background-color: #e9ecef !important;
}

a.bg-lighter:hover, a.bg-lighter:focus,
button.bg-lighter:hover,
button.bg-lighter:focus {
  background-color: #cbd3da !important;
}

.bg-gray-dark {
  background-color: #343a40 !important;
}

a.bg-gray-dark:hover, a.bg-gray-dark:focus,
button.bg-gray-dark:hover,
button.bg-gray-dark:focus {
  background-color: #1d2124 !important;
}

.bg-gradient-default {
  background: linear-gradient(87deg, #172b4d 0, #1a174d 100%) !important;
}

.bg-gradient-primary {
  background: linear-gradient(87deg, #007bff 0, #0011ff 100%) !important;
}

.bg-gradient-secondary {
  background: linear-gradient(87deg, #6c757d 0, #6c6e7d 100%) !important;
}

.bg-gradient-success {
  background: linear-gradient(87deg, #28a745 0, #28a77a 100%) !important;
}

.bg-gradient-info {
  background: linear-gradient(87deg, #17a2b8 0, #175fb8 100%) !important;
}

.bg-gradient-warning {
  background: linear-gradient(87deg, #ffc107 0, #d6ff07 100%) !important;
}

.bg-gradient-danger {
  background: linear-gradient(87deg, #dc3545 0, #dc6b35 100%) !important;
}

.bg-gradient-white {
  background: linear-gradient(87deg, #fff 0, white 100%) !important;
}

.bg-gradient-neutral {
  background: linear-gradient(87deg, #fff 0, white 100%) !important;
}

.bg-gradient-dark {
  background: linear-gradient(87deg, #343a40 0, #343540 100%) !important;
}

.bg-gradient-darker {
  background: linear-gradient(87deg, black 0, black 100%) !important;
}

.bg-gradient-light {
  background: linear-gradient(87deg, #f8f9fa 0, #f8f8fa 100%) !important;
}

.bg-gradient-blue {
  background: linear-gradient(87deg, #007bff 0, #0011ff 100%) !important;
}

.bg-gradient-indigo {
  background: linear-gradient(87deg, #6610f2 0, #c410f2 100%) !important;
}

.bg-gradient-purple {
  background: linear-gradient(87deg, #6f42c1 0, #a442c1 100%) !important;
}

.bg-gradient-pink {
  background: linear-gradient(87deg, #e83e8c 0, #e83e45 100%) !important;
}

.bg-gradient-red {
  background: linear-gradient(87deg, #dc3545 0, #dc6b35 100%) !important;
}

.bg-gradient-orange {
  background: linear-gradient(87deg, #fd7e14 0, #fddf14 100%) !important;
}

.bg-gradient-yellow {
  background: linear-gradient(87deg, #ffc107 0, #d6ff07 100%) !important;
}

.bg-gradient-green {
  background: linear-gradient(87deg, #28a745 0, #28a77a 100%) !important;
}

.bg-gradient-teal {
  background: linear-gradient(87deg, #20c997 0, #20b5c9 100%) !important;
}

.bg-gradient-cyan {
  background: linear-gradient(87deg, #17a2b8 0, #175fb8 100%) !important;
}

.bg-gradient-white {
  background: linear-gradient(87deg, #fff 0, white 100%) !important;
}

.bg-gradient-gray {
  background: linear-gradient(87deg, #6c757d 0, #6c6e7d 100%) !important;
}

.bg-gradient-light {
  background: linear-gradient(87deg, #ced4da 0, #cecfda 100%) !important;
}

.bg-gradient-lighter {
  background: linear-gradient(87deg, #e9ecef 0, #e9eaef 100%) !important;
}

.bg-gradient-gray-dark {
  background: linear-gradient(87deg, #343a40 0, #343540 100%) !important;
}

.section-primary {
  background-color: #fff !important;
}

a.section-primary:hover, a.section-primary:focus,
button.section-primary:hover,
button.section-primary:focus {
  background-color: #e6e6e6 !important;
}

.section-secondary {
  background-color: #6c757d !important;
}

a.section-secondary:hover, a.section-secondary:focus,
button.section-secondary:hover,
button.section-secondary:focus {
  background-color: #545b62 !important;
}

.section-light {
  background-color: #ced4da !important;
}

a.section-light:hover, a.section-light:focus,
button.section-light:hover,
button.section-light:focus {
  background-color: #b1bbc4 !important;
}

.section-dark {
  background-color: #343a40 !important;
}

a.section-dark:hover, a.section-dark:focus,
button.section-dark:hover,
button.section-dark:focus {
  background-color: #1d2124 !important;
}

.section-darker {
  background-color: black !important;
}

a.section-darker:hover, a.section-darker:focus,
button.section-darker:hover,
button.section-darker:focus {
  background-color: black !important;
}

.bg-gradient-default {
  background: linear-gradient(87deg, #172b4d 0, #1a174d 100%) !important;
}

.bg-gradient-primary {
  background: linear-gradient(87deg, #007bff 0, #0011ff 100%) !important;
}

.bg-gradient-secondary {
  background: linear-gradient(87deg, #6c757d 0, #6c6e7d 100%) !important;
}

.bg-gradient-success {
  background: linear-gradient(87deg, #28a745 0, #28a77a 100%) !important;
}

.bg-gradient-info {
  background: linear-gradient(87deg, #17a2b8 0, #175fb8 100%) !important;
}

.bg-gradient-warning {
  background: linear-gradient(87deg, #ffc107 0, #d6ff07 100%) !important;
}

.bg-gradient-danger {
  background: linear-gradient(87deg, #dc3545 0, #dc6b35 100%) !important;
}

.bg-gradient-white {
  background: linear-gradient(87deg, #fff 0, white 100%) !important;
}

.bg-gradient-neutral {
  background: linear-gradient(87deg, #fff 0, white 100%) !important;
}

.bg-gradient-dark {
  background: linear-gradient(87deg, #343a40 0, #343540 100%) !important;
}

.bg-gradient-darker {
  background: linear-gradient(87deg, black 0, black 100%) !important;
}

.bg-gradient-light {
  background: linear-gradient(87deg, #f8f9fa 0, #f8f8fa 100%) !important;
}

.fill-default {
  fill: #172b4d;
}

.stroke-default {
  stroke: #172b4d;
}

.fill-primary {
  fill: #007bff;
}

.stroke-primary {
  stroke: #007bff;
}

.fill-secondary {
  fill: #6c757d;
}

.stroke-secondary {
  stroke: #6c757d;
}

.fill-success {
  fill: #28a745;
}

.stroke-success {
  stroke: #28a745;
}

.fill-info {
  fill: #17a2b8;
}

.stroke-info {
  stroke: #17a2b8;
}

.fill-warning {
  fill: #ffc107;
}

.stroke-warning {
  stroke: #ffc107;
}

.fill-danger {
  fill: #dc3545;
}

.stroke-danger {
  stroke: #dc3545;
}

.fill-white {
  fill: #fff;
}

.stroke-white {
  stroke: #fff;
}

.fill-neutral {
  fill: #fff;
}

.stroke-neutral {
  stroke: #fff;
}

.fill-dark {
  fill: #343a40;
}

.stroke-dark {
  stroke: #343a40;
}

.fill-darker {
  fill: black;
}

.stroke-darker {
  stroke: black;
}

.fill-light {
  fill: #f8f9fa;
}

.stroke-light {
  stroke: #f8f9fa;
}

.fill-opacity-8 {
  fill-opacity: 0.8;
}

.floating {
  animation: floating 3s ease infinite;
  will-change: transform;
}
.floating:hover {
  animation-play-state: paused;
}

.floating-lg {
  animation: floating-lg 3s ease infinite;
}

.floating-sm {
  animation: floating-sm 3s ease infinite;
}

@keyframes floating-lg {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes floating {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes floating-sm {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0px);
  }
}
.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.floatfix:before, .floatfix:after {
  content: "";
  display: table;
}
.floatfix:after {
  clear: both;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.opacity-1 {
  opacity: 0.1 !important;
}

.opacity-2 {
  opacity: 0.2 !important;
}

.opacity-3 {
  opacity: 0.3 !important;
}

.opacity-4 {
  opacity: 0.4 !important;
}

.opacity-5 {
  opacity: 0.5 !important;
}

.opacity-6 {
  opacity: 0.6 !important;
}

.opacity-7 {
  opacity: 0.7 !important;
}

.opacity-8 {
  opacity: 0.8 !important;
}

.opacity-8 {
  opacity: 0.9 !important;
}

.opacity-10 {
  opacity: 1 !important;
}

.h-100vh {
  height: 100vh !important;
}

.row.row-grid > [class*=col-] + [class*=col-] {
  margin-top: 3rem;
}

@media (min-width: 992px) {
  .row.row-grid > [class*=col-lg-] + [class*=col-lg-] {
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .row.row-grid > [class*=col-md-] + [class*=col-md-] {
    margin-top: 0;
  }
}
@media (min-width: 576px) {
  .row.row-grid > [class*=col-sm-] + [class*=col-sm-] {
    margin-top: 0;
  }
}
.row-grid + .row-grid {
  margin-top: 3rem;
}

@media (min-width: 992px) {
  [class*=mt--],
[class*=mr--],
[class*=mb--],
[class*=ml--] {
    position: relative;
    z-index: 5;
  }

  .mt--100 {
    margin-top: -100px !important;
  }

  .mr--100 {
    margin-right: -100px !important;
  }

  .mb--100 {
    margin-bottom: -100px !important;
  }

  .ml--100 {
    margin-left: -100px !important;
  }

  .mt--150 {
    margin-top: -150px !important;
  }

  .mb--150 {
    margin-bottom: -150px !important;
  }

  .mt--200 {
    margin-top: -200px !important;
  }

  .mb--200 {
    margin-bottom: -200px !important;
  }

  .mt--300 {
    margin-top: -300px !important;
  }

  .mb--300 {
    margin-bottom: -300px !important;
  }

  .pt-100 {
    padding-top: 100px !important;
  }

  .pb-100 {
    padding-bottom: 100px !important;
  }

  .pt-150 {
    padding-top: 150px !important;
  }

  .pb-150 {
    padding-bottom: 150px !important;
  }

  .pt-200 {
    padding-top: 200px !important;
  }

  .pb-200 {
    padding-bottom: 200px !important;
  }

  .pt-250 {
    padding-top: 250px !important;
  }

  .pb-250 {
    padding-bottom: 250px !important;
  }

  .pt-300 {
    padding-top: 300px !important;
  }

  .pb-300 {
    padding-bottom: 300px !important;
  }
}
[class*=shadow] {
  transition: all 0.2s ease-in-out;
}

.shadow-sm--hover:hover {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow--hover:hover {
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07) !important;
}

.shadow-lg--hover:hover {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none--hover:hover {
  box-shadow: none !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}

.text-underline {
  text-decoration: underline;
}

.text-through {
  text-decoration: line-through;
}

.lh-100 {
  line-height: 1;
}

.lh-110 {
  line-height: 1.1;
}

.lh-120 {
  line-height: 1.2;
}

.lh-130 {
  line-height: 1.3;
}

.lh-140 {
  line-height: 1.4;
}

.lh-150 {
  line-height: 1.5;
}

.lh-160 {
  line-height: 1.6;
}

.lh-170 {
  line-height: 1.7;
}

.lh-180 {
  line-height: 1.8;
}

.ls-1 {
  letter-spacing: 0.0625rem;
}

.ls-15 {
  letter-spacing: 0.09375rem;
}

.ls-2 {
  letter-spacing: 0.125rem;
}

@media (min-width: 992px) {
  .transform-perspective-right {
    transform: scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg);
  }

  .transform-perspective-left {
    transform: scale(1) perspective(2000px) rotateY(11deg) rotateX(2deg) rotate(-2deg);
  }
}
.alert {
  padding: 0.75rem 1.25rem;
  border: 0;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}
.alert .alert-inner--icon {
  font-size: 1.25rem;
  margin-right: 1.25rem;
  display: inline-block;
  vertical-align: middle;
}
.alert .alert-inner--icon i.ni {
  position: relative;
  top: 1px;
}
.alert .alert-inner--text {
  display: inline-block;
  vertical-align: middle;
}

[class*=alert-] .alert-link {
  color: #fff;
  border-bottom: 1px dotted rgba(255, 255, 255, 0.5);
}

.alert-heading {
  font-weight: 700;
  font-size: 1.5rem;
  margin-top: 0.15rem;
}

.alert-dismissible .close {
  top: 50%;
  right: 1.25rem;
  padding: 0;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.6);
  opacity: 1;
}
.alert-dismissible .close:hover, .alert-dismissible .close:focus {
  color: rgba(255, 255, 255, 0.9);
  opacity: 1 !important;
}
@media (max-width: 575.98px) {
  .alert-dismissible .close {
    top: 1rem;
    right: 0.5rem;
  }
}
.alert-dismissible .close > span:not(.sr-only) {
  font-size: 1.5rem;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.6);
}
.alert-dismissible .close:hover > span:not(.sr-only), .alert-dismissible .close:focus > span:not(.sr-only) {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.9);
}

.avatar {
  color: #fff;
  background-color: #adb5bd;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  border-radius: 50%;
  height: 48px;
  width: 48px;
}

.avatar img {
  width: 100%;
  border-radius: 50%;
}

.avatar + .avatar-content {
  display: inline-block;
  margin-left: 0.75rem;
}

.avatar-lg {
  width: 58px;
  height: 58px;
  font-size: 0.875rem;
}

.avatar-sm {
  width: 38px;
  height: 38px;
  font-size: 0.875rem;
}

.avatar-group .avatar {
  position: relative;
  z-index: 2;
  border: 2px solid #fff;
}
.avatar-group .avatar:hover {
  z-index: 3;
}
.avatar-group .avatar + .avatar {
  margin-left: -1rem;
}

.badge {
  text-transform: uppercase;
}
.badge a {
  color: #FFF;
}

.badge + .badge {
  margin-left: 3px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
}

.badge-circle {
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  font-size: 0.875rem;
}

.badge-inline {
  margin-right: 0.625rem;
}

.badge-inline + span {
  top: 2px;
  position: relative;
}

.badge-inline + span > a {
  text-decoration: underline;
}

.badge-md {
  padding: 0.65em 1em;
}

.badge-lg {
  padding: 0.85em 1.375em;
}

.badge-secondary {
  color: #343a40;
}

.btn .badge:not(:first-child) {
  margin-left: 0.5rem;
}
.btn .badge:not(:last-child) {
  margin-right: 0.5rem;
}

.button__container {
  z-index: 555;
  width: 60%;
  margin: 0px auto;
  position: relative;
}

.button__web {
  width: 228px;
  height: 58px;
  outline: none;
  left: 245px;
  top: 959px;
  background: #4040d4;
  border-radius: 20px 0px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 31px;
  overflow: hidden;
  color: #ccfbff;
}

@media only screen and (max-width: 767px) {
  .button__web {
    width: 140px;
    height: 40px;
    font-size: 20px;
  }
}
.btn {
  position: relative;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  will-change: transform;
  letter-spacing: 0.025em;
  font-size: 0.875rem;
}
.btn:hover {
  transform: translateY(-1px);
}
.btn:not(:last-child) {
  margin-right: 0.5rem;
}

.btn-group .btn,
.input-group .btn {
  margin-right: 0;
  transform: translateY(0);
}

.btn-sm, .btn-group-sm > .btn {
  font-size: 0.75rem;
}

.btn-lg, .btn-group-lg > .btn {
  font-size: 0.875rem;
}

[class*=btn-outline-] {
  border-width: 1px;
}

.btn-outline-secondary {
  color: black;
}

.btn-inner--icon i:not(.fa) {
  position: relative;
  top: 2px;
}

.btn-link {
  font-weight: 400;
  box-shadow: none;
}
.btn-link:hover {
  box-shadow: none;
  transform: none;
}

.btn-neutral {
  color: #007bff;
}

.btn svg:not(:first-child),
.btn i:not(:first-child) {
  margin-left: 0.5rem;
}

.btn svg:not(:last-child),
.btn i:not(:last-child) {
  margin-right: 0.5rem;
}

.btn-icon-label {
  position: relative;
}
.btn-icon-label .btn-inner--icon {
  position: absolute;
  height: 100%;
  line-height: 1;
  border-radius: 0;
  text-align: center;
  margin: 0;
  width: 3em;
  background-color: rgba(0, 0, 0, 0.1);
}
.btn-icon-label .btn-inner--icon:not(:first-child) {
  right: 0;
  top: 0;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}
.btn-icon-label .btn-inner--icon:not(:last-child) {
  left: 0;
  top: 0;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}
.btn-icon-label .btn-inner--icon svg {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.btn-icon-label .btn-inner--text:not(:first-child) {
  padding-left: 3em;
}
.btn-icon-label .btn-inner--text:not(:last-child) {
  padding-right: 3em;
}

.btn-icon .btn-inner--icon img {
  width: 20px;
}
.btn-icon .btn-inner--text:not(:first-child) {
  margin-left: 0.75em;
}
.btn-icon .btn-inner--text:not(:last-child) {
  margin-right: 0.75em;
}

.btn-icon-only {
  width: 2.375rem;
  height: 2.375rem;
  padding: 0;
}

a.btn-icon-only {
  line-height: 2.5;
}

.btn-icon-only.btn-sm, .btn-group-sm > .btn-icon-only.btn {
  width: 2rem;
  height: 2rem;
}

.btn-facebook {
  color: #fff;
  background-color: #3b5999;
  border-color: #3b5999;
}
.btn-facebook:hover {
  color: #fff;
  background-color: #3b5999;
  border-color: #3b5999;
}
.btn-facebook:focus, .btn-facebook.focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 89, 153, 0.5);
}
.btn-facebook.disabled, .btn-facebook:disabled {
  color: #fff;
  background-color: #3b5999;
  border-color: #3b5999;
}
.btn-facebook:not(:disabled):not(.disabled):active, .btn-facebook:not(:disabled):not(.disabled).active, .show > .btn-facebook.dropdown-toggle {
  color: #fff;
  background-color: #2d4474;
  border-color: #3b5999;
}
.btn-facebook:not(:disabled):not(.disabled):active:focus, .btn-facebook:not(:disabled):not(.disabled).active:focus, .show > .btn-facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 89, 153, 0.5);
}

.btn-twitter {
  color: #fff;
  background-color: #1da1f2;
  border-color: #1da1f2;
}
.btn-twitter:hover {
  color: #fff;
  background-color: #1da1f2;
  border-color: #1da1f2;
}
.btn-twitter:focus, .btn-twitter.focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 161, 242, 0.5);
}
.btn-twitter.disabled, .btn-twitter:disabled {
  color: #fff;
  background-color: #1da1f2;
  border-color: #1da1f2;
}
.btn-twitter:not(:disabled):not(.disabled):active, .btn-twitter:not(:disabled):not(.disabled).active, .show > .btn-twitter.dropdown-toggle {
  color: #fff;
  background-color: #0c85d0;
  border-color: #1da1f2;
}
.btn-twitter:not(:disabled):not(.disabled):active:focus, .btn-twitter:not(:disabled):not(.disabled).active:focus, .show > .btn-twitter.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 161, 242, 0.5);
}

.btn-google-plus {
  color: #fff;
  background-color: #dd4b39;
  border-color: #dd4b39;
}
.btn-google-plus:hover {
  color: #fff;
  background-color: #dd4b39;
  border-color: #dd4b39;
}
.btn-google-plus:focus, .btn-google-plus.focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 75, 57, 0.5);
}
.btn-google-plus.disabled, .btn-google-plus:disabled {
  color: #fff;
  background-color: #dd4b39;
  border-color: #dd4b39;
}
.btn-google-plus:not(:disabled):not(.disabled):active, .btn-google-plus:not(:disabled):not(.disabled).active, .show > .btn-google-plus.dropdown-toggle {
  color: #fff;
  background-color: #c23321;
  border-color: #dd4b39;
}
.btn-google-plus:not(:disabled):not(.disabled):active:focus, .btn-google-plus:not(:disabled):not(.disabled).active:focus, .show > .btn-google-plus.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 75, 57, 0.5);
}

.btn-instagram {
  color: #fff;
  background-color: #e4405f;
  border-color: #e4405f;
}
.btn-instagram:hover {
  color: #fff;
  background-color: #e4405f;
  border-color: #e4405f;
}
.btn-instagram:focus, .btn-instagram.focus {
  box-shadow: 0 0 0 0.2rem rgba(228, 64, 95, 0.5);
}
.btn-instagram.disabled, .btn-instagram:disabled {
  color: #fff;
  background-color: #e4405f;
  border-color: #e4405f;
}
.btn-instagram:not(:disabled):not(.disabled):active, .btn-instagram:not(:disabled):not(.disabled).active, .show > .btn-instagram.dropdown-toggle {
  color: #fff;
  background-color: #d31e40;
  border-color: #e4405f;
}
.btn-instagram:not(:disabled):not(.disabled):active:focus, .btn-instagram:not(:disabled):not(.disabled).active:focus, .show > .btn-instagram.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(228, 64, 95, 0.5);
}

.btn-pinterest {
  color: #fff;
  background-color: #bd081c;
  border-color: #bd081c;
}
.btn-pinterest:hover {
  color: #fff;
  background-color: #bd081c;
  border-color: #bd081c;
}
.btn-pinterest:focus, .btn-pinterest.focus {
  box-shadow: 0 0 0 0.2rem rgba(189, 8, 28, 0.5);
}
.btn-pinterest.disabled, .btn-pinterest:disabled {
  color: #fff;
  background-color: #bd081c;
  border-color: #bd081c;
}
.btn-pinterest:not(:disabled):not(.disabled):active, .btn-pinterest:not(:disabled):not(.disabled).active, .show > .btn-pinterest.dropdown-toggle {
  color: #fff;
  background-color: #8c0615;
  border-color: #bd081c;
}
.btn-pinterest:not(:disabled):not(.disabled):active:focus, .btn-pinterest:not(:disabled):not(.disabled).active:focus, .show > .btn-pinterest.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(189, 8, 28, 0.5);
}

.btn-youtube {
  color: #fff;
  background-color: #cd201f;
  border-color: #cd201f;
}
.btn-youtube:hover {
  color: #fff;
  background-color: #cd201f;
  border-color: #cd201f;
}
.btn-youtube:focus, .btn-youtube.focus {
  box-shadow: 0 0 0 0.2rem rgba(205, 32, 31, 0.5);
}
.btn-youtube.disabled, .btn-youtube:disabled {
  color: #fff;
  background-color: #cd201f;
  border-color: #cd201f;
}
.btn-youtube:not(:disabled):not(.disabled):active, .btn-youtube:not(:disabled):not(.disabled).active, .show > .btn-youtube.dropdown-toggle {
  color: #fff;
  background-color: #a11918;
  border-color: #cd201f;
}
.btn-youtube:not(:disabled):not(.disabled):active:focus, .btn-youtube:not(:disabled):not(.disabled).active:focus, .show > .btn-youtube.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(205, 32, 31, 0.5);
}

.btn-slack {
  color: #fff;
  background-color: #3aaf85;
  border-color: #3aaf85;
}
.btn-slack:hover {
  color: #fff;
  background-color: #3aaf85;
  border-color: #3aaf85;
}
.btn-slack:focus, .btn-slack.focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 175, 133, 0.5);
}
.btn-slack.disabled, .btn-slack:disabled {
  color: #fff;
  background-color: #3aaf85;
  border-color: #3aaf85;
}
.btn-slack:not(:disabled):not(.disabled):active, .btn-slack:not(:disabled):not(.disabled).active, .show > .btn-slack.dropdown-toggle {
  color: #fff;
  background-color: #2d8968;
  border-color: #3aaf85;
}
.btn-slack:not(:disabled):not(.disabled):active:focus, .btn-slack:not(:disabled):not(.disabled).active:focus, .show > .btn-slack.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 175, 133, 0.5);
}

.btn-dribbble {
  color: #fff;
  background-color: #ea4c89;
  border-color: #ea4c89;
}
.btn-dribbble:hover {
  color: #fff;
  background-color: #ea4c89;
  border-color: #ea4c89;
}
.btn-dribbble:focus, .btn-dribbble.focus {
  box-shadow: 0 0 0 0.2rem rgba(234, 76, 137, 0.5);
}
.btn-dribbble.disabled, .btn-dribbble:disabled {
  color: #fff;
  background-color: #ea4c89;
  border-color: #ea4c89;
}
.btn-dribbble:not(:disabled):not(.disabled):active, .btn-dribbble:not(:disabled):not(.disabled).active, .show > .btn-dribbble.dropdown-toggle {
  color: #fff;
  background-color: #e51e6b;
  border-color: #ea4c89;
}
.btn-dribbble:not(:disabled):not(.disabled):active:focus, .btn-dribbble:not(:disabled):not(.disabled).active:focus, .show > .btn-dribbble.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(234, 76, 137, 0.5);
}

.btn-github {
  color: #fff;
  background-color: #222222;
  border-color: #222222;
}
.btn-github:hover {
  color: #fff;
  background-color: #222222;
  border-color: #222222;
}
.btn-github:focus, .btn-github.focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
}
.btn-github.disabled, .btn-github:disabled {
  color: #fff;
  background-color: #222222;
  border-color: #222222;
}
.btn-github:not(:disabled):not(.disabled):active, .btn-github:not(:disabled):not(.disabled).active, .show > .btn-github.dropdown-toggle {
  color: #fff;
  background-color: #090909;
  border-color: #222222;
}
.btn-github:not(:disabled):not(.disabled):active:focus, .btn-github:not(:disabled):not(.disabled).active:focus, .show > .btn-github.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
}

.sans-serif {
  font-family: sans-serif;
}

.close {
  transition: all 0.2s ease-in-out;
}
.close > span:not(.sr-only) {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.6);
  line-height: 17px;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 50%;
  font-size: 1.25rem;
  display: block;
  transition: all 0.2s ease-in-out;
}
.close:hover, .close:focus {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.9);
  outline: none;
}
.close:hover span:not(.sr-only), .close:focus span:not(.sr-only) {
  background-color: transparent;
}

.custom-control-label::before {
  border: 1px solid #ced4da;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.custom-control-label span {
  position: relative;
  top: 2px;
}

.custom-control-label {
  margin-bottom: 0;
}

.custom-control-input:active ~ .custom-control-label::before {
  border-color: #007bff;
}

.custom-control-alternative .custom-control-label::before {
  border: 0;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
}
.custom-control-alternative .custom-control-input:checked ~ .custom-control-label::before {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.custom-control-alternative .custom-control-input:active ~ .custom-control-label::before, .custom-control-alternative .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
}

.custom-checkbox .custom-control-input ~ .custom-control-label {
  cursor: pointer;
  font-size: 0.875rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #007bff;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:disabled ~ .custom-control-label::before {
  border-color: #e9ecef;
}
.custom-checkbox .custom-control-input:disabled:checked::before {
  border-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-input ~ .custom-control-label {
  cursor: pointer;
  font-size: 0.875rem;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #007bff;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}
.custom-radio .custom-control-input:disabled ~ .custom-control-label::before {
  border-color: #e9ecef;
}
.custom-radio .custom-control-input:disabled:checked::before {
  border-color: rgba(0, 123, 255, 0.5);
}

.custom-toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 1.5rem;
}
.custom-toggle input {
  display: none;
}
.custom-toggle input:checked + .custom-toggle-slider {
  border: 1px solid #007bff;
}
.custom-toggle input:checked + .custom-toggle-slider:before {
  background: #007bff;
  transform: translateX(1.625rem);
}
.custom-toggle input:disabled + .custom-toggle-slider {
  border: 1px solid #e9ecef;
}
.custom-toggle input:disabled:checked + .custom-toggle-slider {
  border: 1px solid #e9ecef;
}
.custom-toggle input:disabled:checked + .custom-toggle-slider:before {
  background-color: #3395ff;
}

.custom-toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #ced4da;
  border-radius: 34px !important;
  background-color: transparent;
}
.custom-toggle-slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 2px;
  border-radius: 50% !important;
  background-color: #ddd;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.dropdown,
.dropup,
.dropright,
.dropleft {
  display: inline-block;
}

.dropdown-menu {
  min-width: 12rem;
}
.dropdown-menu .dropdown-item {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
}
.dropdown-menu .dropdown-item > i,
.dropdown-menu .dropdown-item > svg {
  margin-right: 1rem;
  font-size: 1rem;
  vertical-align: -17%;
}

.dropdown-header {
  padding-left: 1rem;
  padding-right: 1rem;
  color: #f8f9fa;
  font-size: 0.625rem;
  text-transform: uppercase;
  font-weight: 700;
}

.dropdown-menu-inverse {
  background: #282f37;
  border-color: #242a31;
}
.dropdown-menu-inverse .dropdown-item {
  color: #dadada;
}
.dropdown-menu-inverse .dropdown-item:active, .dropdown-menu-inverse .dropdown-item:focus, .dropdown-menu-inverse .dropdown-item:hover {
  color: #fff;
  background: #31353e;
}
.dropdown-menu-inverse .dropdown-divider {
  background: #191e23;
}

.dropdown-menu a.media > div:first-child {
  line-height: 1;
}
.dropdown-menu a.media p {
  color: #6c757d;
}
.dropdown-menu a.media:hover .heading, .dropdown-menu a.media:hover p {
  color: #172b4d !important;
}

.dropdown-menu-sm {
  min-width: 100px;
  border: 0.3rem;
}

.dropdown-menu-lg {
  min-width: 260px;
  border-radius: 0.3rem;
}

.dropdown-menu-xl {
  min-width: 450px;
  border-radius: 0.3rem;
}

.footer {
  background: #6c757d;
  padding: 3rem 0;
}
.footer .col-footer .heading {
  color: #6c757d;
  letter-spacing: 0;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 1rem;
}
.footer .nav .nav-item .nav-link,
.footer .footer-link {
  color: #6c757d !important;
}
.footer .nav .nav-item .nav-link:hover,
.footer .footer-link:hover {
  color: #495057 !important;
}
.footer .list-unstyled li a {
  display: inline-block;
  padding: 0.125rem 0;
  color: #6c757d;
  font-size: 0.85rem;
}
.footer .list-unstyled li a:hover {
  color: #495057;
}
.footer .copyright {
  font-size: 0.875rem;
}

.footer-dark .col-footer .heading {
  color: #fff;
}

.footer.has-cards {
  overflow: hidden;
  padding-top: 500px;
  margin-top: -420px;
  position: relative;
  background: transparent;
  pointer-events: none;
}
.footer.has-cards:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 600px;
  height: 2000px;
  background: #6c757d;
  transform: skew(0, -8deg);
}
.footer.has-cards .container {
  pointer-events: auto;
  position: relative;
}

.nav-footer .nav-link {
  font-size: 0.875rem;
}
.nav-footer .nav-item:last-child .nav-link {
  padding-right: 0;
}

/* Form controls */
label {
  font-size: 0.875rem;
}

.form-control {
  font-size: 0.875rem;
}
.form-control:focus::placeholder {
  color: #adb5bd;
}

/* Textareas */
textarea[resize=none] {
  resize: none !important;
}

textarea[resize=both] {
  resize: both !important;
}

textarea[resize=vertical] {
  resize: vertical !important;
}

textarea[resize=horizontal] {
  resize: horizontal !important;
}

/* Alternative styles */
.form-control-muted {
  background-color: #EDF0F5;
  border-color: #EDF0F5;
  box-shadow: none;
}
.form-control-muted:focus {
  background-color: #EDF0F5;
}

.form-control-alternative {
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  border: 0;
  transition: box-shadow 0.15s ease;
}
.form-control-alternative:focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.form-control-lg {
  font-size: 1rem;
}

.has-success,
.has-danger {
  position: relative;
}
.has-success:after,
.has-danger:after {
  width: 19px;
  height: 19px;
  line-height: 19px;
  text-align: center;
  font-family: "NucleoIcons";
  display: inline-block;
  position: absolute;
  right: 15px;
  top: 2px;
  transform: translateY(50%);
  border-radius: 50%;
  font-size: 9px;
  opacity: 1;
}

.has-success:after {
  content: "\ea26";
  color: #165d26;
  background-color: #48d368;
}
.has-success .form-control {
  background-color: #fff;
}
.has-success .form-control:focus {
  border-color: rgba(50, 151, 211, 0.25);
}
.has-success .form-control::placeholder {
  color: #28a745;
}
.has-danger:after {
  content: "\ea53";
  color: #9a1b27;
  background-color: #ffd454;
}
.has-danger .form-control {
  background-color: #fff;
}
.has-danger .form-control:focus {
  border-color: rgba(50, 151, 211, 0.25);
}
.has-danger .form-control::placeholder {
  color: #dc3545;
}
@media (min-width: 992px) {
  .container-lg {
    max-width: 1160px;
  }
}
.icon {
  width: 3rem;
  height: 3rem;
}
.icon i, .icon svg {
  font-size: 2.25rem;
}

.icon + .icon-text {
  padding-left: 1rem;
  width: calc(100% - 3rem - 1);
}

.icon-xl {
  width: 5rem;
  height: 5rem;
}
.icon-xl i, .icon-xl svg {
  font-size: 4.25rem;
}

.icon-xl + .icon-text {
  width: calc(100% - 5rem - 1);
}

.icon-lg {
  width: 4rem;
  height: 4rem;
}
.icon-lg i, .icon-lg svg {
  font-size: 3.25rem;
}

.icon-lg + .icon-text {
  width: calc(100% - 4rem - 1);
}

.icon-sm {
  width: 2rem;
  height: 2rem;
}
.icon-sm i, .icon-sm svg {
  font-size: 1.25rem;
}

.icon-sm + .icon-text {
  width: calc(100% - 2rem - 1);
}

.icon-shape {
  padding: 12px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.icon-shape i, .icon-shape svg {
  font-size: 1.25rem;
}
.icon-shape.icon-lg i, .icon-shape.icon-lg svg {
  font-size: 1.625rem;
}
.icon-shape.icon-sm i, .icon-shape.icon-sm svg {
  font-size: 0.875rem;
}
.icon-shape svg {
  width: 30px;
  height: 30px;
}

.icon-shape-default {
  color: #091428;
  background-color: rgba(35, 65, 116, 0.5);
}

.icon-shape-primary {
  color: #0062cc;
  background-color: rgba(51, 149, 255, 0.5);
}

.icon-shape-secondary {
  color: #4b5c6b;
  background-color: rgba(134, 142, 150, 0.5);
}

.icon-shape-success {
  color: #168630;
  background-color: rgba(52, 206, 87, 0.5);
}

.icon-shape-info {
  color: #0a8092;
  background-color: rgba(31, 200, 227, 0.5);
}

.icon-shape-warning {
  color: #d39e00;
  background-color: rgba(255, 206, 58, 0.5);
}

.icon-shape-danger {
  color: #c81627;
  background-color: rgba(228, 96, 109, 0.5);
}

.icon-shape-white {
  color: #e8e3e3;
  background-color: rgba(255, 255, 255, 0.5);
}

.icon-shape-neutral {
  color: #e8e3e3;
  background-color: rgba(255, 255, 255, 0.5);
}

.icon-shape-dark {
  color: #1a2127;
  background-color: rgba(75, 84, 92, 0.5);
}

.icon-shape-darker {
  color: black;
  background-color: rgba(23, 26, 28, 0.5);
}

.icon-shape-light {
  color: #d7e0e8;
  background-color: rgba(255, 255, 255, 0.5);
}

.input-group {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
}
.input-group .form-control {
  box-shadow: none;
}
.input-group .form-control:not(:first-child) {
  border-left: 0;
  padding-left: 0;
}
.input-group .form-control:not(:last-child) {
  border-right: 0;
  padding-right: 0;
}
.input-group .form-control:focus {
  box-shadow: none;
}

.input-group-text {
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.input-group-alternative {
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  border: 0;
  transition: box-shadow 0.15s ease;
}
.input-group-alternative .form-control,
.input-group-alternative .input-group-text {
  border: 0;
  box-shadow: none;
}

.focused .input-group-alternative {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
}

.focused .input-group {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.focused .input-group-text {
  color: #495057;
  background-color: #fff;
  border-color: rgba(50, 151, 211, 0.25);
}
.focused .form-control {
  border-color: rgba(50, 151, 211, 0.25);
}

.list-group-space .list-group-item {
  margin-bottom: 1.5rem;
  border-radius: 0.25rem;
}

.list-group-img {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  vertical-align: top;
  margin: -0.1rem 1.2rem 0 -0.2rem;
}

.list-group-content {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-width: 0;
}

.list-group-content > p {
  color: #adb5bd;
  line-height: 1.5;
  margin: 0.2rem 0 0;
}

.list-group-heading {
  font-size: 1rem;
  color: #343a40;
}

.list-group-heading > small {
  float: right;
  color: #adb5bd;
  font-weight: 500;
}

.modal-content {
  border: 0;
  border-radius: 0.3rem;
}

.modal-fluid .modal-dialog {
  margin-top: 0;
  margin-bottom: 0;
}
.modal-fluid .modal-content {
  border-radius: 0;
}

.modal-default .modal-title {
  color: #fff;
}
.modal-default .modal-header,
.modal-default .modal-footer {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-default .modal-content {
  background-color: #172b4d;
  color: #fff;
}
.modal-default .modal-content .heading {
  color: #fff;
}
.modal-default .close > span:not(.sr-only) {
  color: #fff;
}

.modal-primary .modal-title {
  color: #fff;
}
.modal-primary .modal-header,
.modal-primary .modal-footer {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-primary .modal-content {
  background-color: #007bff;
  color: #fff;
}
.modal-primary .modal-content .heading {
  color: #fff;
}
.modal-primary .close > span:not(.sr-only) {
  color: #fff;
}

.modal-secondary .modal-title {
  color: #fff;
}
.modal-secondary .modal-header,
.modal-secondary .modal-footer {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-secondary .modal-content {
  background-color: #6c757d;
  color: #fff;
}
.modal-secondary .modal-content .heading {
  color: #fff;
}
.modal-secondary .close > span:not(.sr-only) {
  color: #fff;
}

.modal-success .modal-title {
  color: #fff;
}
.modal-success .modal-header,
.modal-success .modal-footer {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-success .modal-content {
  background-color: #28a745;
  color: #fff;
}
.modal-success .modal-content .heading {
  color: #fff;
}
.modal-success .close > span:not(.sr-only) {
  color: #fff;
}

.modal-info .modal-title {
  color: #fff;
}
.modal-info .modal-header,
.modal-info .modal-footer {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-info .modal-content {
  background-color: #17a2b8;
  color: #fff;
}
.modal-info .modal-content .heading {
  color: #fff;
}
.modal-info .close > span:not(.sr-only) {
  color: #fff;
}

.modal-warning .modal-title {
  color: #212529;
}
.modal-warning .modal-header,
.modal-warning .modal-footer {
  border-color: rgba(33, 37, 41, 0.075);
}
.modal-warning .modal-content {
  background-color: #ffc107;
  color: #212529;
}
.modal-warning .modal-content .heading {
  color: #212529;
}
.modal-warning .close > span:not(.sr-only) {
  color: #fff;
}

.modal-danger .modal-title {
  color: #fff;
}
.modal-danger .modal-header,
.modal-danger .modal-footer {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-danger .modal-content {
  background-color: #dc3545;
  color: #fff;
}
.modal-danger .modal-content .heading {
  color: #fff;
}
.modal-danger .close > span:not(.sr-only) {
  color: #fff;
}

.modal-white .modal-title {
  color: #212529;
}
.modal-white .modal-header,
.modal-white .modal-footer {
  border-color: rgba(33, 37, 41, 0.075);
}
.modal-white .modal-content {
  background-color: #fff;
  color: #212529;
}
.modal-white .modal-content .heading {
  color: #212529;
}
.modal-white .close > span:not(.sr-only) {
  color: #fff;
}

.modal-neutral .modal-title {
  color: #212529;
}
.modal-neutral .modal-header,
.modal-neutral .modal-footer {
  border-color: rgba(33, 37, 41, 0.075);
}
.modal-neutral .modal-content {
  background-color: #fff;
  color: #212529;
}
.modal-neutral .modal-content .heading {
  color: #212529;
}
.modal-neutral .close > span:not(.sr-only) {
  color: #fff;
}

.modal-dark .modal-title {
  color: #fff;
}
.modal-dark .modal-header,
.modal-dark .modal-footer {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-dark .modal-content {
  background-color: #343a40;
  color: #fff;
}
.modal-dark .modal-content .heading {
  color: #fff;
}
.modal-dark .close > span:not(.sr-only) {
  color: #fff;
}

.modal-darker .modal-title {
  color: #fff;
}
.modal-darker .modal-header,
.modal-darker .modal-footer {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-darker .modal-content {
  background-color: black;
  color: #fff;
}
.modal-darker .modal-content .heading {
  color: #fff;
}
.modal-darker .close > span:not(.sr-only) {
  color: #fff;
}

.modal-light .modal-title {
  color: #212529;
}
.modal-light .modal-header,
.modal-light .modal-footer {
  border-color: rgba(33, 37, 41, 0.075);
}
.modal-light .modal-content {
  background-color: #f8f9fa;
  color: #212529;
}
.modal-light .modal-content .heading {
  color: #212529;
}
.modal-light .close > span:not(.sr-only) {
  color: #fff;
}

.nav-link {
  color: #495057;
}
.nav-link:hover {
  color: #007bff;
}
.nav-link i:not(.fa) {
  position: relative;
  top: 2px;
}

.nav-pills .nav-item:not(:last-child) {
  padding-right: 1rem;
}
.nav-pills .nav-link {
  padding: 0.75rem 1rem;
  color: #007bff;
  font-weight: 500;
  font-size: 0.875rem;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  transition: all 0.2s ease-in-out;
}
.nav-pills .nav-link:hover {
  color: #006fe6;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}
@media (max-width: 575.98px) {
  .nav-pills .nav-item {
    margin-bottom: 1rem;
  }
}

@media (max-width: 767.98px) {
  .nav-pills:not(.nav-pills-circle) .nav-item {
    padding-right: 0;
  }
}
.nav-pills-circle .nav-link {
  text-align: center;
  height: 60px;
  width: 60px;
  padding: 0;
  line-height: 60px;
  border-radius: 50%;
}
.nav-pills-circle .nav-link-icon i, .nav-pills-circle .nav-link-icon svg {
  font-size: 1rem;
}

.nav-wrapper {
  padding: 1rem 0;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-wrapper + .card {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.navbar-nav .nav-link {
  font-size: 0.9rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  text-transform: normal;
  letter-spacing: 0;
  transition: all 0.15s linear;
}
.navbar-nav .nav-link .nav-link-inner--text {
  margin-left: 0.25rem;
}

.navbar-brand {
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.875rem;
  letter-spacing: 0.05px;
}
.navbar-brand img {
  height: 30px;
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-light .navbar-brand {
  color: #343a40;
}

.navbar-nav .nav-item .media:not(:last-child) {
  margin-bottom: 1.5rem;
}

@media (min-width: 992px) {
  .navbar-main {
    padding: 1rem 0;
  }

  .navbar-nav .nav-item {
    margin-right: 0.5rem;
  }
  .navbar-nav .nav-item [data-toggle=dropdown]::after {
    transition: all 0.2s ease-in-out;
  }
  .navbar-nav .nav-item.show [data-toggle=dropdown]::after {
    transform: rotate(180deg);
  }
  .navbar-nav .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0.25rem;
  }
  .navbar-nav .nav-link i {
    margin-right: 0.625rem;
  }
  .navbar-nav .nav-link-icon {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    font-size: 1rem;
    border-radius: 0.25rem;
  }
  .navbar-nav .nav-link-icon i {
    margin-right: 0;
  }
  .navbar-nav .dropdown-menu {
    opacity: 0;
    pointer-events: none;
    margin: 0;
  }
  .navbar-nav .dropdown-menu:before {
    background: #fff;
    box-shadow: none;
    content: "";
    display: block;
    height: 16px;
    width: 16px;
    left: 20px;
    position: absolute;
    bottom: 100%;
    transform: rotate(-45deg) translateY(1rem);
    z-index: -5;
    border-radius: 0.2rem;
  }
  .navbar-nav .dropdown-menu-right:before {
    right: 20px;
    left: auto;
  }
  .navbar-nav:not(.navbar-nav-hover) .dropdown-menu.show {
    opacity: 1;
    pointer-events: auto;
    animation: show-navbar-dropdown 0.25s ease forwards;
  }
  .navbar-nav:not(.navbar-nav-hover) .dropdown-menu.close {
    display: block;
    animation: hide-navbar-dropdown 0.15s ease backwards;
  }
  .navbar-nav.navbar-nav-hover .dropdown-menu {
    opacity: 0;
    display: block;
    pointer-events: none;
    transform: translate(0, 10px) perspective(200px) rotateX(-2deg);
    transition: visibility 0.25s, opacity 0.25s, transform 0.25s;
  }
  .navbar-nav.navbar-nav-hover .nav-item.dropdown:hover > .dropdown-menu {
    display: block;
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translate(0, 0);
    animation: none;
  }
  .navbar-nav .dropdown-menu-inner {
    position: relative;
    padding: 1rem;
  }
}
.navbar-transparent {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: transparent;
  border: 0;
  box-shadow: none;
}
.navbar-transparent .navbar-brand {
  color: white;
}
.navbar-transparent .navbar-toggler {
  color: white;
}
.navbar-transparent .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

@media (min-width: 768px) {
  .navbar-transparent .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.95);
  }
  .navbar-transparent .navbar-nav .nav-link:hover, .navbar-transparent .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.65);
  }
  .navbar-transparent .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
  }
  .navbar-transparent .navbar-nav .show > .nav-link,
.navbar-transparent .navbar-nav .active > .nav-link,
.navbar-transparent .navbar-nav .nav-link.show,
.navbar-transparent .navbar-nav .nav-link.active {
    color: rgba(255, 255, 255, 0.65);
  }
  .navbar-transparent .navbar-brand {
    color: rgba(255, 255, 255, 0.95);
  }
  .navbar-transparent .navbar-brand:hover, .navbar-transparent .navbar-brand:focus {
    color: rgba(255, 255, 255, 0.95);
  }
}
.navbar-collapse-header {
  display: none;
}

@media (max-width: 991.98px) {
  .navbar-nav .nav-link {
    padding: 0.625rem 0;
    color: #172b4d !important;
  }
  .navbar-nav .dropdown-menu {
    box-shadow: none;
    min-width: auto;
  }
  .navbar-nav .dropdown-menu .media svg {
    width: 30px;
  }

  .navbar-collapse {
    width: calc(100% - 1.4rem);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1050;
    margin: 0.7rem;
    overflow-y: auto;
    height: auto !important;
    opacity: 0;
  }
  .navbar-collapse .navbar-toggler {
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;
    display: inline-block;
    padding: 0;
  }
  .navbar-collapse .navbar-toggler span {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    opacity: 1;
    background: #283448;
  }
  .navbar-collapse .navbar-toggler :nth-child(1) {
    transform: rotate(135deg);
  }
  .navbar-collapse .navbar-toggler :nth-child(2) {
    transform: rotate(-135deg);
  }
  .navbar-collapse .navbar-collapse-header {
    display: block;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-collapse .collapse-brand img {
    height: 36px;
  }
  .navbar-collapse .collapse-close {
    text-align: right;
  }

  .navbar-collapse.collapsing,
.navbar-collapse.show {
    padding: 1.5rem;
    border-radius: 0.25rem;
    background: #FFF;
    box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
    animation: show-navbar-collapse 0.2s ease forwards;
  }

  .navbar-collapse.collapsing-out {
    animation: hide-navbar-collapse 0.2s ease forwards;
  }
}
@keyframes show-navbar-collapse {
  0% {
    opacity: 0;
    transform: scale(0.95);
    transform-origin: 100% 0;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes hide-navbar-collapse {
  from {
    opacity: 1;
    transform: scale(1);
    transform-origin: 100% 0;
  }
  to {
    opacity: 0;
    transform: scale(0.95);
  }
}
@keyframes show-navbar-dropdown {
  0% {
    opacity: 0;
    transform: translate(0, 10px) perspective(200px) rotateX(-2deg);
    transition: visibility 0.25s, opacity 0.25s, transform 0.25s;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes hide-navbar-dropdown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate(0, 10px);
  }
}
.page-item.active .page-link {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}
.page-item .page-link,
.page-item span {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0 3px;
  border-radius: 50% !important;
  width: 36px;
  height: 36px;
  font-size: 0.875rem;
}

.pagination-lg .page-item .page-link,
.pagination-lg .page-item span {
  width: 46px;
  height: 46px;
  line-height: 46px;
}

.pagination-sm .page-item .page-link,
.pagination-sm .page-item span {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.popover {
  border: 0;
}

.popover-header {
  font-weight: 700;
}

.popover-default {
  background-color: #172b4d;
}
.popover-default .popover-header {
  background-color: #172b4d;
  color: #fff;
}
.popover-default .popover-body {
  color: #fff;
}
.popover-default .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-default.bs-popover-top .arrow::after, .popover-default.bs-popover-auto[x-placement^=top] .arrow::after {
  border-top-color: #172b4d;
}
.popover-default.bs-popover-right .arrow::after, .popover-default.bs-popover-auto[x-placement^=right] .arrow::after {
  border-right-color: #172b4d;
}
.popover-default.bs-popover-bottom .arrow::after, .popover-default.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: #172b4d;
}
.popover-default.bs-popover-left .arrow::after, .popover-default.bs-popover-auto[x-placement^=left] .arrow::after {
  border-left-color: #172b4d;
}

.popover-primary {
  background-color: #007bff;
}
.popover-primary .popover-header {
  background-color: #007bff;
  color: #fff;
}
.popover-primary .popover-body {
  color: #fff;
}
.popover-primary .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-primary.bs-popover-top .arrow::after, .popover-primary.bs-popover-auto[x-placement^=top] .arrow::after {
  border-top-color: #007bff;
}
.popover-primary.bs-popover-right .arrow::after, .popover-primary.bs-popover-auto[x-placement^=right] .arrow::after {
  border-right-color: #007bff;
}
.popover-primary.bs-popover-bottom .arrow::after, .popover-primary.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: #007bff;
}
.popover-primary.bs-popover-left .arrow::after, .popover-primary.bs-popover-auto[x-placement^=left] .arrow::after {
  border-left-color: #007bff;
}

.popover-secondary {
  background-color: #6c757d;
}
.popover-secondary .popover-header {
  background-color: #6c757d;
  color: #fff;
}
.popover-secondary .popover-body {
  color: #fff;
}
.popover-secondary .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-secondary.bs-popover-top .arrow::after, .popover-secondary.bs-popover-auto[x-placement^=top] .arrow::after {
  border-top-color: #6c757d;
}
.popover-secondary.bs-popover-right .arrow::after, .popover-secondary.bs-popover-auto[x-placement^=right] .arrow::after {
  border-right-color: #6c757d;
}
.popover-secondary.bs-popover-bottom .arrow::after, .popover-secondary.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: #6c757d;
}
.popover-secondary.bs-popover-left .arrow::after, .popover-secondary.bs-popover-auto[x-placement^=left] .arrow::after {
  border-left-color: #6c757d;
}

.popover-success {
  background-color: #28a745;
}
.popover-success .popover-header {
  background-color: #28a745;
  color: #fff;
}
.popover-success .popover-body {
  color: #fff;
}
.popover-success .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-success.bs-popover-top .arrow::after, .popover-success.bs-popover-auto[x-placement^=top] .arrow::after {
  border-top-color: #28a745;
}
.popover-success.bs-popover-right .arrow::after, .popover-success.bs-popover-auto[x-placement^=right] .arrow::after {
  border-right-color: #28a745;
}
.popover-success.bs-popover-bottom .arrow::after, .popover-success.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: #28a745;
}
.popover-success.bs-popover-left .arrow::after, .popover-success.bs-popover-auto[x-placement^=left] .arrow::after {
  border-left-color: #28a745;
}

.popover-info {
  background-color: #17a2b8;
}
.popover-info .popover-header {
  background-color: #17a2b8;
  color: #fff;
}
.popover-info .popover-body {
  color: #fff;
}
.popover-info .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-info.bs-popover-top .arrow::after, .popover-info.bs-popover-auto[x-placement^=top] .arrow::after {
  border-top-color: #17a2b8;
}
.popover-info.bs-popover-right .arrow::after, .popover-info.bs-popover-auto[x-placement^=right] .arrow::after {
  border-right-color: #17a2b8;
}
.popover-info.bs-popover-bottom .arrow::after, .popover-info.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: #17a2b8;
}
.popover-info.bs-popover-left .arrow::after, .popover-info.bs-popover-auto[x-placement^=left] .arrow::after {
  border-left-color: #17a2b8;
}

.popover-warning {
  background-color: #ffc107;
}
.popover-warning .popover-header {
  background-color: #ffc107;
  color: #212529;
}
.popover-warning .popover-body {
  color: #212529;
}
.popover-warning .popover-header {
  border-color: rgba(33, 37, 41, 0.2);
}
.popover-warning.bs-popover-top .arrow::after, .popover-warning.bs-popover-auto[x-placement^=top] .arrow::after {
  border-top-color: #ffc107;
}
.popover-warning.bs-popover-right .arrow::after, .popover-warning.bs-popover-auto[x-placement^=right] .arrow::after {
  border-right-color: #ffc107;
}
.popover-warning.bs-popover-bottom .arrow::after, .popover-warning.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: #ffc107;
}
.popover-warning.bs-popover-left .arrow::after, .popover-warning.bs-popover-auto[x-placement^=left] .arrow::after {
  border-left-color: #ffc107;
}

.popover-danger {
  background-color: #dc3545;
}
.popover-danger .popover-header {
  background-color: #dc3545;
  color: #fff;
}
.popover-danger .popover-body {
  color: #fff;
}
.popover-danger .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-danger.bs-popover-top .arrow::after, .popover-danger.bs-popover-auto[x-placement^=top] .arrow::after {
  border-top-color: #dc3545;
}
.popover-danger.bs-popover-right .arrow::after, .popover-danger.bs-popover-auto[x-placement^=right] .arrow::after {
  border-right-color: #dc3545;
}
.popover-danger.bs-popover-bottom .arrow::after, .popover-danger.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: #dc3545;
}
.popover-danger.bs-popover-left .arrow::after, .popover-danger.bs-popover-auto[x-placement^=left] .arrow::after {
  border-left-color: #dc3545;
}

.popover-white {
  background-color: #fff;
}
.popover-white .popover-header {
  background-color: #fff;
  color: #212529;
}
.popover-white .popover-body {
  color: #212529;
}
.popover-white .popover-header {
  border-color: rgba(33, 37, 41, 0.2);
}
.popover-white.bs-popover-top .arrow::after, .popover-white.bs-popover-auto[x-placement^=top] .arrow::after {
  border-top-color: #fff;
}
.popover-white.bs-popover-right .arrow::after, .popover-white.bs-popover-auto[x-placement^=right] .arrow::after {
  border-right-color: #fff;
}
.popover-white.bs-popover-bottom .arrow::after, .popover-white.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: #fff;
}
.popover-white.bs-popover-left .arrow::after, .popover-white.bs-popover-auto[x-placement^=left] .arrow::after {
  border-left-color: #fff;
}

.popover-neutral {
  background-color: #fff;
}
.popover-neutral .popover-header {
  background-color: #fff;
  color: #212529;
}
.popover-neutral .popover-body {
  color: #212529;
}
.popover-neutral .popover-header {
  border-color: rgba(33, 37, 41, 0.2);
}
.popover-neutral.bs-popover-top .arrow::after, .popover-neutral.bs-popover-auto[x-placement^=top] .arrow::after {
  border-top-color: #fff;
}
.popover-neutral.bs-popover-right .arrow::after, .popover-neutral.bs-popover-auto[x-placement^=right] .arrow::after {
  border-right-color: #fff;
}
.popover-neutral.bs-popover-bottom .arrow::after, .popover-neutral.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: #fff;
}
.popover-neutral.bs-popover-left .arrow::after, .popover-neutral.bs-popover-auto[x-placement^=left] .arrow::after {
  border-left-color: #fff;
}

.popover-dark {
  background-color: #343a40;
}
.popover-dark .popover-header {
  background-color: #343a40;
  color: #fff;
}
.popover-dark .popover-body {
  color: #fff;
}
.popover-dark .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-dark.bs-popover-top .arrow::after, .popover-dark.bs-popover-auto[x-placement^=top] .arrow::after {
  border-top-color: #343a40;
}
.popover-dark.bs-popover-right .arrow::after, .popover-dark.bs-popover-auto[x-placement^=right] .arrow::after {
  border-right-color: #343a40;
}
.popover-dark.bs-popover-bottom .arrow::after, .popover-dark.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: #343a40;
}
.popover-dark.bs-popover-left .arrow::after, .popover-dark.bs-popover-auto[x-placement^=left] .arrow::after {
  border-left-color: #343a40;
}

.popover-darker {
  background-color: black;
}
.popover-darker .popover-header {
  background-color: black;
  color: #fff;
}
.popover-darker .popover-body {
  color: #fff;
}
.popover-darker .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-darker.bs-popover-top .arrow::after, .popover-darker.bs-popover-auto[x-placement^=top] .arrow::after {
  border-top-color: black;
}
.popover-darker.bs-popover-right .arrow::after, .popover-darker.bs-popover-auto[x-placement^=right] .arrow::after {
  border-right-color: black;
}
.popover-darker.bs-popover-bottom .arrow::after, .popover-darker.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: black;
}
.popover-darker.bs-popover-left .arrow::after, .popover-darker.bs-popover-auto[x-placement^=left] .arrow::after {
  border-left-color: black;
}

.popover-light {
  background-color: #f8f9fa;
}
.popover-light .popover-header {
  background-color: #f8f9fa;
  color: #212529;
}
.popover-light .popover-body {
  color: #212529;
}
.popover-light .popover-header {
  border-color: rgba(33, 37, 41, 0.2);
}
.popover-light.bs-popover-top .arrow::after, .popover-light.bs-popover-auto[x-placement^=top] .arrow::after {
  border-top-color: #f8f9fa;
}
.popover-light.bs-popover-right .arrow::after, .popover-light.bs-popover-auto[x-placement^=right] .arrow::after {
  border-right-color: #f8f9fa;
}
.popover-light.bs-popover-bottom .arrow::after, .popover-light.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: #f8f9fa;
}
.popover-light.bs-popover-left .arrow::after, .popover-light.bs-popover-auto[x-placement^=left] .arrow::after {
  border-left-color: #f8f9fa;
}

.progress-wrapper {
  position: relative;
  padding-top: 1.5rem;
}

.progress {
  height: 8px;
  margin-bottom: 1rem;
  overflow: hidden;
  border-radius: 0.2rem;
  background-color: #e9ecef;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress .sr-only {
  width: auto;
  height: 20px;
  margin: 0 0 0 30px;
  left: 0;
  clip: auto;
  line-height: 20px;
  font-size: 13px;
}

.progress-heading {
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 2px;
  padding: 0;
}

.progress-bar {
  box-shadow: none;
  border-radius: 0;
  height: auto;
}

.progress-info {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.progress-label span {
  display: inline-block;
  color: #007bff;
  font-size: 0.625rem;
  font-weight: 600;
  text-transform: uppercase;
  background: rgba(0, 123, 255, 0.1);
  padding: 0.25rem 1rem;
  border-radius: 30px;
}

.progress-percentage {
  text-align: right;
}
.progress-percentage span {
  display: inline-block;
  color: #6c757d;
  font-size: 0.875rem;
  font-weight: 600;
}

.section {
  position: relative;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.section-xl {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.section-lg {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.section-sm {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

@media (min-width: 768px) {
  .section-hero {
    height: 100vh;
    min-height: 500px;
  }
}

.section-shaped {
  margin: 180px 0;
  position: relative;
}
.section-shaped .stars-and-coded {
  margin-top: 8rem;
}
.section-shaped .shape {
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.section-shaped .shape span {
  position: absolute;
}
.section-shaped .shape + .container {
  position: relative;
  padding-top: 6rem;
  padding-bottom: 0rem;
  height: 100%;
}
.section-shaped .shape.shape-skew + .container {
  padding-top: 0;
}
.section-shaped .shape.shape-skew + .container .col {
  margin-top: -100px;
}
.section-shaped .shape.shape-skew + .shape-container {
  padding-top: 18rem;
  padding-bottom: 19rem;
}
.section-shaped .shape-skew {
  transform: skewY(-8deg);
  transform-origin: 0;
}
.section-shaped .shape-skew span {
  transform: skew(8deg);
}
.section-shaped .shape-primary {
  background: linear-gradient(150deg, #281483 15%, #8f6ed5 70%, #d782d9 94%);
}
.section-shaped .shape-primary :nth-child(1) {
  background: #53f;
}
.section-shaped .shape-primary :nth-child(2) {
  background: #4553ff;
}
.section-shaped .shape-primary :nth-child(3) {
  background: #4f40ff;
}
.section-shaped .shape-primary :nth-child(4) {
  background: #25ddf5;
}
.section-shaped .shape-primary :nth-child(5) {
  background: #1fa2ff;
}
.section-shaped .shape-default {
  background: linear-gradient(150deg, #7795f8 15%, #6772e5 70%, #555abf 94%);
}
.section-shaped .shape-default :nth-child(1) {
  background: #7795f8;
}
.section-shaped .shape-default :nth-child(2) {
  background: #7b9aff;
}
.section-shaped .shape-default :nth-child(3) {
  background: #6f8ff8;
}
.section-shaped .shape-default :nth-child(4) {
  background: #76eea7;
}
.section-shaped .shape-default :nth-child(5) {
  background: #6adaff;
}
.section-shaped .shape-light {
  background: linear-gradient(150deg, shapes-light-color("step-1-gradient-bg") 15%, shapes-light-color("step-2-gradient-bg") 70%, shapes-light-color("step-3-gradient-bg") 94%);
}
.section-shaped .shape-light :nth-child(1) {
  background: shapes-light-color("span-1-bg");
}
.section-shaped .shape-light :nth-child(2) {
  background: shapes-light-color("span-2-bg");
}
.section-shaped .shape-light :nth-child(3) {
  background: shapes-light-color("span-3-bg");
}
.section-shaped .shape-light :nth-child(4) {
  background: shapes-light-color("span-4-bg");
}
.section-shaped .shape-light :nth-child(5) {
  background: shapes-light-color("span-5-bg");
}
.section-shaped .shape-dark {
  background: linear-gradient(150deg, #32325d 15%, #32325d 70%, #32325d 94%);
}
.section-shaped .shape-dark :nth-child(1) {
  background: #2e2e57;
}
.section-shaped .shape-dark :nth-child(2) {
  background: #2b2b58;
}
.section-shaped .shape-dark :nth-child(3) {
  background: #25254d;
}
.section-shaped .shape-dark :nth-child(4) {
  background: #d782d9;
}
.section-shaped .shape-dark :nth-child(5) {
  background: #008169;
}
.section-shaped .shape-style-1 span {
  height: 120px;
  width: 120px;
  border-radius: 50%;
}
.section-shaped .shape-style-1 .span-200 {
  height: 200px;
  width: 200px;
}
.section-shaped .shape-style-1 .span-150 {
  height: 150px;
  width: 150px;
}
.section-shaped .shape-style-1 .span-100 {
  height: 100px;
  width: 100px;
}
.section-shaped .shape-style-1 .span-75 {
  height: 75px;
  width: 75px;
}
.section-shaped .shape-style-1 .span-50 {
  height: 50px;
  width: 50px;
}
.section-shaped .shape-style-1 :nth-child(1) {
  left: -4%;
  bottom: auto;
  background: rgba(255, 255, 255, 0.1);
}
.section-shaped .shape-style-1 :nth-child(2) {
  right: 4%;
  top: 10%;
  background: rgba(255, 255, 255, 0.1);
}
.section-shaped .shape-style-1 :nth-child(3) {
  top: 280px;
  right: 5.66666%;
  background: rgba(255, 255, 255, 0.3);
}
.section-shaped .shape-style-1 :nth-child(4) {
  top: 320px;
  right: 7%;
  background: rgba(255, 255, 255, 0.15);
}
.section-shaped .shape-style-1 :nth-child(5) {
  top: 38%;
  left: 1%;
  right: auto;
  background: rgba(255, 255, 255, 0.05);
}
.section-shaped .shape-style-1 :nth-child(6) {
  width: 200px;
  height: 200px;
  top: 44%;
  left: 10%;
  right: auto;
  background: rgba(255, 255, 255, 0.15);
}
.section-shaped .shape-style-1 :nth-child(7) {
  bottom: 50%;
  right: 36%;
  background: rgba(255, 255, 255, 0.04);
}
.section-shaped .shape-style-1 :nth-child(8) {
  bottom: 70px;
  right: 2%;
  background: rgba(255, 255, 255, 0.2);
}
.section-shaped .shape-style-1 :nth-child(9) {
  bottom: 1%;
  right: 2%;
  background: rgba(255, 255, 255, 0.1);
}
.section-shaped .shape-style-1 :nth-child(10) {
  bottom: 1%;
  left: 1%;
  right: auto;
  background: rgba(255, 255, 255, 0.05);
}
@media (max-width: 991.98px) {
  .section-shaped .shape-style-1 span {
    height: 120px;
  }
}
@media (max-width: 767.98px) {
  .section-shaped .shape-style-1 span {
    height: 90px;
  }
}
.section-shaped .shape-style-1.shape-primary {
  background: linear-gradient(150deg, #281483 15%, #8f6ed5 70%, #d782d9 94%);
}
.section-shaped .shape-style-1.shape-default {
  background: linear-gradient(150deg, #7795f8 15%, #6772e5 70%, #555abf 94%);
}
.section-shaped .shape-style-1.shape-light {
  background: linear-gradient(150deg, shapes-light-color("step-1-gradient-bg") 15%, shapes-light-color("step-2-gradient-bg") 70%, shapes-light-color("step-3-gradient-bg") 94%);
}
.section-shaped .shape-style-1.shape-dark {
  background: linear-gradient(150deg, #32325d 15%, #32325d 70%, #32325d 94%);
}
.section-shaped .shape-style-2 span {
  height: 190px;
}
.section-shaped .shape-style-2 .span-sm {
  height: 100px;
}
.section-shaped .shape-style-2 :nth-child(1) {
  width: 33.33333%;
  top: 0;
  left: -16.66666%;
}
.section-shaped .shape-style-2 :nth-child(2) {
  width: 33.33333%;
  top: 0;
  left: 16.66666%;
  right: auto;
}
.section-shaped .shape-style-2 :nth-child(3) {
  width: 33.33333%;
  left: 49.99999%;
  bottom: auto;
}
.section-shaped .shape-style-2 :nth-child(4) {
  width: 33.33333%;
  top: 55%;
  right: -16.66666%;
}
.section-shaped .shape-style-2 :nth-child(5) {
  width: 33.33333%;
  bottom: 0;
}
@media (max-width: 991.98px) {
  .section-shaped .shape-style-2 span {
    height: 120px;
  }
}
@media (max-width: 767.98px) {
  .section-shaped .shape-style-2 span {
    height: 90px;
  }
}
.section-shaped .shape-style-3 span {
  height: 140px;
}
.section-shaped .shape-style-3 .span-sm {
  height: 100px;
}
.section-shaped .shape-style-3 :nth-child(1) {
  width: 66%;
  left: -16.66666%;
  bottom: auto;
}
.section-shaped .shape-style-3 :nth-child(2) {
  width: 40%;
  top: 54%;
  right: -16.66666%;
}
.section-shaped .shape-style-3 :nth-child(3) {
  width: 33.33333%;
  top: 34%;
  left: -16.66666%;
  right: auto;
}
.section-shaped .shape-style-3 :nth-child(4) {
  width: 60%;
  bottom: 0;
  right: -16.66666%;
  opacity: 0.6;
}
.section-shaped .shape-style-3 :nth-child(5) {
  width: 33.33333%;
  bottom: 0;
}
@media (max-width: 991.98px) {
  .section-shaped .shape-style-3 span {
    height: 120px;
  }
}
@media (max-width: 767.98px) {
  .section-shaped .shape-style-3 span {
    height: 90px;
  }
}

.device-ill {
  pointer-events: none;
  position: absolute;
  display: flex;
  width: 1287px;
  left: 50%;
  margin-left: -644px;
  transform: scale(0.5) rotate(-12deg) translateX(50px);
  transform-origin: 50% 20%;
  will-change: transform;
}
.device-ill div {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  padding: 0.875rem;
  background: #fff;
  box-shadow: inset 0 4px 7px 1px #fff, inset 0 -5px 20px rgba(173, 186, 204, 0.25), 0 2px 6px rgba(0, 21, 64, 0.14), 0 10px 20px rgba(0, 21, 64, 0.05);
}
.device-ill .tablet-landscape {
  width: 512px;
  height: 352px;
  margin: 115px 50px 0;
}

@media (min-width: 670px) {
  .device-ill {
    flex-wrap: wrap;
    width: 512px;
    margin-left: -50px;
    top: 215px;
    transform: rotate(-12deg);
    transform-origin: 100% 0;
  }
  .device-ill [class^=tablet] {
    margin: 0;
  }
  .device-ill .tablet-landscape {
    width: 512px;
    height: 352px;
  }
}
@media (min-width: 880px) {
  .device-ill {
    width: 829px;
    margin-left: -10px;
    top: 20px;
  }
  .device-ill .tablet-landscape {
    align-self: flex-end;
    margin-right: 50px;
  }
  .device-ill .phone-big {
    display: flex;
    width: 267px;
    height: 553px;
  }
}
.section-profile-cover {
  height: 580px;
  background-size: cover;
  background-position: center center;
}

@media (max-width: 991.98px) {
  .section-profile-cover {
    height: 400px;
  }
}
.section-components > .form-control + .form-control {
  margin-top: 0.5rem;
}
.section-components > .nav + .nav,
.section-components > .alert + .alert,
.section-components > .navbar + .navbar,
.section-components > .progress + .progress,
.section-components > .progress + .btn,
.section-components .badge,
.section-components .btn {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.section-components .btn-group {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.section-components .btn-group .btn {
  margin: 0;
}
.section-components .alert {
  margin: 0;
}
.section-components .alert + .alert {
  margin-top: 1.25rem;
}
.section-components .badge {
  margin-right: 0.5rem;
}
.section-components .modal-footer .btn {
  margin: 0;
}

.floating-cards {
  -webkit-perspective: 1500px;
  perspective: 1500px;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.floating-cards > div {
  position: absolute;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 8px;
  overflow: hidden;
}

.floating-cards .shine {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
}

p {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.7;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.7;
  margin-top: 1.5rem;
}
.lead + .btn-wrapper {
  margin-top: 3rem;
}

.description {
  font-size: 0.875rem;
}

.heading {
  letter-spacing: 0.025em;
  font-size: 0.95rem;
  text-transform: uppercase;
  font-weight: 500;
}

.heading-title {
  letter-spacing: 0.025em;
  font-size: 1.375rem;
  font-weight: 700;
  text-transform: uppercase;
}

.heading-section {
  letter-spacing: 0.025em;
  font-size: 1.375rem;
  font-weight: 700;
  text-transform: uppercase;
}
.heading-section img {
  display: block;
  width: 72px;
  height: 72px;
  margin-bottom: 1.5rem;
}
.heading-section.text-center img {
  margin-left: auto;
  margin-right: auto;
}

.display-1 span,
.display-2 span,
.display-3 span,
.display-4 span {
  display: block;
  font-weight: 300;
}

article h4:not(:first-child),
article h5:not(:first-child) {
  margin-top: 3rem;
}
article h4, article h5 {
  margin-bottom: 1.5rem;
}
article figure {
  margin: 3rem 0;
}
article h5 + figure {
  margin-top: 0;
}

.flatpickr-calendar .flatpickr-day.selected:hover, .flatpickr-calendar .flatpickr-day.selected, .flatpickr-calendar .flatpickr-day:hover {
  background: #007bff;
  color: #fff;
  box-shadow: none;
  border: none;
}

.flatpickr-calendar {
  padding: 20px 22px;
  width: 347px;
  color: #495057;
}
.flatpickr-calendar .flatpickr-weekday {
  text-align: center;
  font-size: 0.875rem;
  color: #495057;
  font-weight: normal;
}
.flatpickr-calendar .flatpickr-day {
  border: none;
}
.flatpickr-calendar .flatpickr-day.today {
  border: 1px solid #007bff;
}
.flatpickr-calendar .flatpickr-day.today:hover {
  background: #007bff;
  color: #fff;
}
.flatpickr-calendar .flatpickr-day.inRange {
  background: #007bff !important;
  color: #fff;
  box-shadow: -5px 0 0 #007bff, 5px 0 0 #007bff;
  border: none !important;
}
.flatpickr-calendar .flatpickr-day.startRange, .flatpickr-calendar .flatpickr-day.endRange {
  background: #007bff;
}
.flatpickr-calendar .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-calendar .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-calendar .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 0 #007bff;
}
.flatpickr-calendar .flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-calendar .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #007bff;
}
.flatpickr-calendar .flatpickr-current-month span.cur-month,
.flatpickr-calendar .flatpickr-current-month input.cur-year {
  padding: 0 10px;
  color: #495057;
  font-size: 0.875rem;
  font-weight: 500;
}
.flatpickr-calendar .flatpickr-current-month span.cur-month:hover,
.flatpickr-calendar .flatpickr-current-month input.cur-year:hover {
  background: #e9ecef;
  border-radius: 0.25rem;
}

.headroom {
  will-change: transform;
  transition: all 0.2s ease-in-out;
  background-color: inherit;
}

.headroom--pinned {
  transform: translateY(0%);
}

.headroom--unpinned {
  transform: translateY(-100%);
}

.headroom--not-top {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #172b4d !important;
  box-shadow: 0 1px 10px rgba(130, 130, 134, 0.1);
}

.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  touch-action: none;
  user-select: none;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
  direction: ltr;
}

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform-origin: 0 0;
}

html:not([dir=rtl]) .noUi-horizontal .noUi-origin {
  left: auto;
  right: 0;
}

.noUi-vertical .noUi-origin {
  width: 0;
}

.noUi-horizontal .noUi-origin {
  height: 0;
}

.noUi-handle {
  position: absolute;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  transition: transform 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

.noUi-horizontal {
  height: 5px;
}

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  left: -17px;
  top: -6px;
}

.noUi-vertical {
  width: 5px;
}

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  left: -6px;
  top: -17px;
}

html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
  right: -17px;
  left: auto;
}

.noUi-connects {
  border-radius: 3px;
}

.noUi-connect {
  background: #007bff;
}

.noUi-draggable {
  cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}

.noUi-handle {
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;
  outline: none;
}

.noUi-active {
  outline: none;
}

/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #B8B8B8;
}

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}

/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-pips {
  position: absolute;
  color: #999;
}

/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}

.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}

/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #CCC;
}

.noUi-marker-sub {
  background: #AAA;
}

.noUi-marker-large {
  background: #AAA;
}

/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}

.noUi-value-horizontal {
  transform: translate(-50%, 50%);
}

.noUi-rtl .noUi-value-horizontal {
  transform: translate(50%, 50%);
}

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}

/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%, 0);
  padding-left: 25px;
}

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}

.noUi-target {
  background: #eceeef;
  border-radius: 5px;
  border: 0;
  box-shadow: inset 0 1px 2px rgba(90, 97, 105, 0.1);
  margin: 15px 0;
  cursor: pointer;
}

.noUi-horizontal {
  height: 5px;
}

html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
  right: -10px;
}

.noUi-vertical {
  width: 5px;
}

.noUi-connect {
  background: #007bff;
  box-shadow: none;
}

.noUi-horizontal .noUi-handle,
.noUi-vertical .noUi-handle {
  top: -5px;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  box-shadow: none;
  cursor: pointer;
  background-color: #007bff;
  border: 0;
  transition: box-shadow 0.15s, transform 0.15s;
}

.noUi-horizontal .noUi-handle.noUi-active,
.noUi-vertical .noUi-handle.noUi-active {
  transform: scale(1.2);
}

.input-slider--cyan .noUi-connect {
  background: #17a2b8;
}

.input-slider--cyan.noUi-horizontal .noUi-handle,
.input-slider--cyan.noUi-vertical .noUi-handle {
  background-color: #17a2b8;
}

.input-slider--red .noUi-connect {
  background: #dc3545;
}

.input-slider--red.noUi-horizontal .noUi-handle,
.input-slider--red.noUi-vertical .noUi-handle {
  background-color: #dc3545;
}

.input-slider--green .noUi-connect {
  background: #28a745;
}

.input-slider--green.noUi-horizontal .noUi-handle,
.input-slider--green.noUi-vertical .noUi-handle {
  background-color: #28a745;
}

.input-slider--yellow .noUi-connect {
  background: #ffc107;
}

.input-slider--yellow.noUi-horizontal .noUi-handle,
.input-slider--yellow.noUi-vertical .noUi-handle {
  background-color: #ffc107;
}

.input-slider--pink .noUi-connect {
  background: #e83e8c;
}

.input-slider--pink.noUi-horizontal .noUi-handle,
.input-slider--pink.noUi-vertical .noUi-handle {
  background-color: #e83e8c;
}

/* Disabled state */
[disabled] .noUi-connect,
[disabled].noUi-connect {
  background: #b2b2b2;
}

[disabled] .noUi-handle,
[disabled].noUi-origin {
  cursor: not-allowed;
}

/* Range slider value labels */
.range-slider-value {
  font-size: 0.75rem;
  font-weight: 500;
  background-color: rgba(52, 58, 64, 0.7);
  color: #fff;
  border-radius: 10px;
  padding: 0.4em 0.8em 0.3em 0.85em;
}

.range-slider-wrapper .upper-info {
  font-weight: 400;
  margin-bottom: 5px;
}

.input-slider-value-output {
  background: #333;
  color: #fff;
  padding: 4px 8px;
  position: relative;
  top: 12px;
  font-size: 11px;
  border-radius: 2px;
}

.input-slider-value-output:after {
  bottom: 100%;
  left: 10px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #333;
  border-width: 4px;
  margin-left: -4px;
}

.input-slider-value-output.left:after {
  left: 10px;
  right: auto;
}

.input-slider-value-output.right:after {
  right: 10px;
  left: auto;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #298eb0;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1F7998;
}

body {
  background: #393E46 !important;
  font-family: "Raleway", sans-serif !important;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.each__page {
  padding-top: 88px;
  min-height: 80vh;
}

.p-0_5 {
  padding: 0.15rem !important;
}

a:hover {
  color: lightseagreen;
  text-decoration: none;
}

input[type=text]:focus, input[type=email]:focus, input[type=password]:focus {
  outline: none;
}

.cart_div {
  position: relative;
}

.count_cart {
  position: absolute;
  width: 70%;
  height: 70%;
  background-color: #00ADB5 !important;
  border-radius: 50%;
  left: 70%;
  top: -15%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.count_cart_web {
  position: absolute;
  width: 40%;
  height: 85%;
  border-radius: 50%;
  left: 10%;
  top: -40%;
  background-color: #00ADB5 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #EEEEEE !important;
  font-family: sans-serif;
  font-size: 14px;
}

@media only screen and (max-width: 1056px) {
  .each__page {
    padding-top: 60px;
  }
}
.menu {
  width: 100%;
  display: flex;
  justify-content: space-around;
  height: 88px;
  position: fixed !important;
  background: #222831 !important;
  box-sizing: border-box;
  z-index: 8888;
  box-shadow: 0 10px 4px rgba(0, 0, 0, 0.25);
}

.menu__left_side, .menu__right_side {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #222831 !important;
}

.menu__left_side {
  width: 70%;
}

.menu__right_side {
  width: 30%;
}

.menu__right_side > div {
  width: 33%;
  justify-content: center;
}

.menu__right_side > .adapt-shop {
  width: 15%;
}

.menu__items {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #EEEEEE !important;
  text-decoration: none;
  font-family: "Raleway", sans-serif !important;
  user-select: none;
  cursor: pointer;
}

.router-link-active {
  color: #00ADB5 !important;
  font-weight: bold !important;
  text-shadow: 0 15px 4px rgba(0, 0, 0, 0.25);
}

.menu__right_side > .lang {
  display: flex;
  justify-content: space-around;
}

.login {
  display: flex;
  justify-content: center;
  gap: 3px;
  align-items: center;
}

.adapt-menu-mobile {
  display: none;
}

.adapt-shop > div {
  display: flex;
  justify-content: center;
}

.menu__items > .lang-icon {
  width: 30px;
  height: 20px;
  cursor: pointer;
}

.menu__items > .user-img {
  width: 30px;
  height: 30px;
}

.menu__items > .logo {
  margin-top: 10px;
  width: 120px;
}

@media only screen and (max-width: 1456px) {
  .menu__items {
    font-size: 13px;
  }
}
@media only screen and (max-width: 1056px) {
  .menu {
    height: 60px;
  }

  .menu, .menu__left_side, .menu__right_side {
    flex-direction: column;
    justify-content: left;
    text-align: left;
    z-index: 999999;
  }

  .menu, .menu__left_side > div, .menu__right_side > div {
    width: 100%;
  }

  .login {
    justify-content: left;
  }

  .menu__items {
    padding: 20px 20px;
  }

  .adapt-logo, .adapt-shop {
    display: none;
  }

  .menu__dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu__btn_open {
    width: 100px;
    height: 40px;
    background: #EEEEEE !important;
    border: 2px solid #00ADB5 !important;
    box-sizing: border-box;
    border-radius: 8px;
    text-align: center;
    vertical-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
  }

  .menu_txt {
    font-family: "Raleway", sans-serif !important;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000;
  }

  .adapt-menu-mobile {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    width: 90%;
    left: 5%;
  }

  .menu__left_side, .menu__right_side {
    position: absolute;
    top: -500px;
    width: 100%;
    transition: 0.5s;
  }
}
.anim {
  animation-name: heartbeat;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  display: inline-block;
  color: #c20e19;
}

@keyframes heartbeat {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}
.footer-distributed {
  background: #222831 !important;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  font: bold 16px sans-serif;
  padding: 55px 50px;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right {
  display: inline-block;
  vertical-align: top;
}

/* Footer left */
.footer-distributed .footer-left {
  width: 40%;
}

/* The company logo */
.footer-distributed h3 {
  color: #EEEEEE !important;
  font: normal 36px "Open Sans", cursive;
  margin: 0;
}

.footer-distributed h3 span {
  color: #00ADB5 !important;
}

/* Footer links */
.footer-distributed .footer-links {
  color: #EEEEEE !important;
  margin: 20px 0 12px;
  padding: 0;
}

.footer-distributed .footer-links a {
  display: inline-block;
  line-height: 1.8;
  font-weight: 400;
  text-decoration: none;
  color: inherit;
}

.footer-distributed .footer-company-name {
  color: #EEEEEE !important;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

/* Footer Center */
.footer-distributed .footer-center {
  width: 35%;
}

.footer-distributed .footer-center i {
  background-color: #393E46 !important;
  color: #EEEEEE !important;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  margin: 10px 15px;
  vertical-align: middle;
  font-size: 17px;
  line-height: 38px;
}

.footer-distributed .footer-center p {
  display: inline-block;
  color: #EEEEEE !important;
  font-weight: 400;
  vertical-align: middle;
  margin: 0;
}

.footer-distributed .footer-center a {
  display: inline-block;
  color: #EEEEEE !important;
  font-weight: 400;
  vertical-align: middle;
  text-decoration: none;
  margin: 0;
}

.footer-distributed .footer-center a span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}

.footer-distributed .footer-center p span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}

.footer-distributed .footer-center p a {
  color: #00ADB5 !important;
  text-decoration: none;
}

.footer-distributed .footer-links a:before {
  content: "|";
  font-weight: 300;
  font-size: 20px;
  left: 0;
  color: #EEEEEE !important;
  display: inline-block;
  padding-right: 5px;
}

.footer-distributed .footer-links .link-1:before {
  content: none;
}

/* Footer Right */
.footer-distributed .footer-right {
  width: 20%;
}

.footer-distributed .footer-company-about {
  line-height: 20px;
  color: #EEEEEE !important;
  font-size: 13px;
  font-weight: normal;
  margin: 0;
}

.footer-distributed .footer-company-about span {
  display: block;
  color: #EEEEEE !important;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer-distributed .footer-icons {
  margin-top: 25px;
}

.footer-distributed .footer-icons a {
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color: #222831 !important;
  border-radius: 2px;
  font-size: 20px;
  color: #EEEEEE !important;
  text-align: center;
  line-height: 35px;
  margin-right: 3px;
  margin-bottom: 5px;
}

/* If you don't want the footer to be responsive, remove these media queries */
@media (max-width: 880px) {
  .footer-distributed {
    font: bold 14px sans-serif;
  }

  .footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right {
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }
  .footer-distributed .footer-left h3,
.footer-distributed .footer-center h3,
.footer-distributed .footer-right h3 {
    font-size: 24px;
  }

  .footer-distributed .footer-center i {
    margin-left: 0;
  }
}
.partners {
  width: 75px;
  position: absolute;
  background: transparent;
  border: 1px solid #00ADB5 !important;
  box-sizing: border-box;
  box-shadow: -8px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px 0px;
  padding: 1px;
  top: 88px;
  left: 0;
  overflow: hidden;
  z-index: 55555;
}

.partners > div > img {
  width: 72px;
  margin: auto;
}

@media only screen and (max-width: 1056px) {
  .partners {
    top: 60px;
  }
}
@media only screen and (max-width: 768px) {
  .partners {
    display: none;
  }
}
.error-alert {
  padding: 10px;
  color: #D8000C;
  background-color: #FFBABA;
  margin: 10px 0;
  border-radius: 3px 3px 3px 3px;
}

.auth_container {
  margin: 20px auto;
}

.form-container > form > h1 {
  font-size: 34px;
  font-family: "Raleway", sans-serif !important;
}

.overlay-panel > h1 {
  font-size: 44px;
  font-family: "Raleway", sans-serif !important;
}

.overlay-panel > p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
  font-family: "Raleway", sans-serif !important;
}

.sign-in-container > form > a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
  font-family: "Raleway", sans-serif !important;
}

.buttonchik {
  border-radius: 20px;
  border: 1px solid #00ADB5 !important;
  background-color: #00ADB5 !important;
  color: #EEEEEE !important;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 25px;
  margin-top: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  font-family: "Raleway", sans-serif !important;
  cursor: pointer;
}

.buttonchik:active {
  transform: scale(0.95);
}

.buttonchik:focus {
  outline: none;
}

.buttonchik.ghost {
  background-color: transparent;
  border-color: #EEEEEE !important;
}

.buttonchik.ghost:hover {
  color: #EEEEEE !important;
}

.form-container > form {
  background-color: #EEEEEE !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px;
  height: 100%;
  text-align: center;
}

.form-container > form > input {
  background-color: #EEEEEE !important;
  border: 1px solid #00ADB5 !important;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  font-family: "Raleway", sans-serif !important;
}

.auth_container > .container {
  background-color: #EEEEEE !important;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 480px;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.container.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.container.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {
  0%, 49.99% {
    opacity: 0;
    z-index: 1;
  }
  50%, 100% {
    opacity: 1;
    z-index: 5;
  }
}
.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.container.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.overlay {
  background: #00ADB5 !important;
  background: -webkit-linear-gradient(to right, #00ADB5 !important, #22c9c0);
  background: linear-gradient(to right, #00ADB5 !important, #22c9c0);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #EEEEEE !important;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.container.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.social-container {
  margin: 20px 0;
}

.social-container a {
  border: 1px solid #EEEEEE !important;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}

@media only screen and (max-width: 767px) {
  .auth_container {
    padding: 0 10px;
  }

  .form-container > form {
    padding: 5px;
  }

  .overlay-panel > h1 {
    font-size: 28px;
  }
}
.product__card {
  height: 530px;
  width: 300px;
  background: #222831 !important;
  box-shadow: 10px 10px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px 20px;
  padding: 10px;
  margin-top: 10px;
}

.product__card-logo-block {
  display: flex;
  justify-content: right;
}

.product__card-logo img {
  width: 66px;
  height: 31px;
}

.product_presentation {
  display: flex;
  /*flex-direction: column;*/
  justify-content: center;
}

.product_img {
  width: 200px;
  height: 200px;
  background-color: #EEEEEE !important;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.product__description {
  display: flex;
  justify-content: center;
}

.product__description > div {
  margin-top: 32px;
  width: 70%;
}

.product__description p {
  font-family: sans-serif;
  font-size: 18px;
  line-height: 23px;
  color: #EEEEEE !important;
}

.product__buttons {
  margin-top: 22px;
  display: flex;
  justify-content: space-around;
}

.old-price, .new-price {
  width: 125px;
  height: 42px;
  border-radius: 20px 5px;
  font-family: sans-serif;
  font-size: 16px;
  line-height: 21px;
  color: #00ADB5 !important;
  cursor: auto !important;
}

.product__add {
  width: 180px;
  height: 60px;
  background: #393E46 !important;
  border: 3px solid #00ADB5 !important;
  border-radius: 5px 20px;
  font-family: sans-serif;
  font-size: 16px;
  line-height: 21px;
  color: #EEEEEE !important;
  cursor: pointer;
  outline: none;
}

.old-price {
  text-decoration: line-through;
  background: #EEEEEE !important;
  outline: none;
  border: none;
}

.new-price {
  background: #00ADB5 !important;
  color: #EEEEEE !important;
  outline: none;
  border: none;
}

.product__footer {
  margin: 41px auto;
  width: 70%;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
}

.product__footer p {
  font-family: "Raleway", sans-serif !important;
  font-size: 13px;
  line-height: 17px;
  color: #CCFBFF;
}

.page__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
}

.__page {
  border: 1px solid #222831 !important;
  padding: 10px;
  user-select: none;
  cursor: pointer;
  color: #EEEEEE !important;
}

.current-page {
  border: 2px solid #00ADB5 !important;
  color: #00ADB5 !important;
}

.about_component {
  width: 80%;
  margin: 50px auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.about_component > div {
  width: 45%;
  height: 600px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about_component_description,
.about_component_car_image-circle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.about_component_car_image-circle {
  width: 500px;
  height: 500px;
  background: #EEEEEE !important;
  border: 5px solid #00ADB5 !important;
  border-radius: 50%;
}

.about_component_description-title {
  font-family: "Raleway", sans-serif !important;
}

.about_component_description-title span {
  font-family: "Raleway", sans-serif !important;
  color: #EEEEEE !important;
}

.about_component_description-title {
  font-family: "Raleway", sans-serif !important;
  font-size: 64px;
  line-height: 83px;
  color: #00ADB5 !important;
}

.about_component_description-text {
  margin-top: 20px;
  font-family: "Raleway", sans-serif !important;
  font-size: 20px;
  line-height: 38px;
  color: #EEEEEE !important;
}

.btn-container {
  margin-top: 47px;
  font-family: "Raleway", sans-serif !important;
  display: flex;
  justify-content: left;
}

@media only screen and (max-width: 1580px) {
  .about_component > div {
    width: 50%;
  }
}
@media only screen and (max-width: 1281px) {
  .about_component_car_image-circle {
    width: 500px;
    height: 500px;
  }
}
@media only screen and (max-width: 1056px) {
  .about_component {
    flex-direction: column;
    margin-top: 70px;
  }

  .about_component_car_image {
    height: 50px !important;
    display: flex;
    justify-content: center;
    gap: 40px;
  }

  .about_component_car_image-circle {
    width: 163px;
    height: 163px;
  }

  .about_component_description-title {
    display: none;
  }

  .about_component_description {
    align-items: flex-start;
    height: auto !important;
  }

  .about_component > div {
    width: 100%;
    margin-top: 30px;
  }
}
.car-image {
  width: 100%;
  height: 100vh;
}

.third-color {
  color: #00ADB5 !important;
}

.fourth-color {
  color: #EEEEEE !important;
}

.car_color {
  display: flex;
  justify-content: center;
}
.car_color > div {
  width: 50%;
  height: 800px;
  background-color: black;
}

.color__background {
  height: 100%;
  width: 100%;
  z-index: 0;
}

.color_content {
  position: absolute;
  width: 50%;
  height: 100vh;
  left: 50%;
  z-index: 10;
  overflow: hidden;
}

.car_img {
  width: 50%;
}
.car_img > img {
  width: 100%;
  height: 100%;
}

.color__title {
  margin-top: 30px;
  text-align: center;
}
.color__title > p, .color__title > span {
  font-family: "Raleway", sans-serif !important;
  font-size: 30px;
  line-height: 10rem;
}

.color__description {
  width: 90%;
  margin: auto;
}
.color__description > p {
  font-family: "Raleway", sans-serif !important;
  font-size: 1rem;
  line-height: 2rem;
  color: #EEEEEE !important;
}

@media only screen and (max-width: 1350px) {
  .color__title {
    width: 100%;
  }

  .color__description > p {
    font-size: 20px;
    line-height: 40px;
  }
}
@media only screen and (max-width: 1056px) {
  .color_content {
    margin-bottom: 120px;
  }

  .car_color > div {
    background-color: #03080b;
  }

  .color__title {
    font-size: 39px;
  }

  .car_color > div {
    height: 575px;
    background-size: cover;
  }

  .color__description > p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 767px) {
  .car-image {
    height: 50vh;
  }

  .color_content {
    width: 50%;
    margin: 0 auto 120px;
  }

  .color__title {
    margin-top: 50%;
    line-height: 50px;
    font-size: 26px;
  }

  .color__title > p, .color__title > span {
    font-size: 26px;
    line-height: 52px;
  }

  .color__description > p {
    display: none;
  }

  .block__present img {
    width: 100px !important;
  }

  .partner__block {
    height: 500px !important;
  }
}
@media only screen and (max-width: 380px) {
  .color__title > p, .color__title > span {
    font-size: 20px;
    line-height: 52px;
  }
}
.partner__block {
  height: 609px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.block__present {
  padding-top: 45px;
  padding-bottom: 45px;
  height: 362px;
  width: 60%;
  background: #222831 !important;
  border: 5px solid #00ADB5 !important;
  box-sizing: border-box;
  box-shadow: 12px 5px 12px 12px rgba(0, 0, 0, 0.25);
  border-radius: 20px 0;
  color: #EEEEEE !important;
}

.partners__title p {
  font-size: 28px;
  line-height: 48px;
  text-align: center;
}

.partners__logo_present {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.partners__logo_present img {
  width: 200px;
}

@keyframes border-flicker {
  0% {
    opacity: 0.1;
    -webkit-box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
    -moz-box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
    box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
  }
  2% {
    opacity: 1;
    -webkit-box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
    -moz-box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
    box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
  }
  4% {
    opacity: 0.1;
    -webkit-box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
    -moz-box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
    box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
  }
  8% {
    opacity: 1;
    -webkit-box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
    -moz-box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
    box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
  }
  70% {
    opacity: 0.7;
    -webkit-box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
    -moz-box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
    box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
  }
  100% {
    opacity: 1;
    -webkit-box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
    -moz-box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
    box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
  }
}
@keyframes letter-flicker {
  0% {
    opacity: 0.1;
    text-shadow: 0px 0px 29px #f21616;
  }
  2% {
    opacity: 0.1;
    text-shadow: 0px 0px 29px #f21616;
  }
  4% {
    opacity: 1;
    text-shadow: 0px 0px 29px #f21616;
  }
  19% {
    opacity: 1;
    text-shadow: 0px 0px 29px #f21616;
  }
  21% {
    opacity: 0.1;
    text-shadow: 0px 0px 29px #f21616;
  }
  23% {
    opacity: 1;
    text-shadow: 0px 0px 29px #f21616;
  }
  80% {
    opacity: 1;
    text-shadow: 0px 0px 29px #f21616;
  }
  83% {
    opacity: 0.4;
    text-shadow: 0px 0px 29px #f21616;
  }
  87% {
    opacity: 1;
    text-shadow: 0px 0px 29px #f21616;
  }
}
@media only screen and (max-width: 1558px) {
  .block__present {
    width: 90%;
  }
}
@media only screen and (max-width: 1155px) {
  .block__present {
    width: 80%;
  }
}
@media only screen and (max-width: 1056px) {
  .block__present {
    width: 100%;
    height: 100%;
  }

  .partner__block {
    height: 400px;
  }
}
@media only screen and (max-width: 767px) {
  .partner__block {
    height: 350px !important;
  }

  .partners__title p {
    font-size: 22px;
    line-height: 40px;
    text-align: center;
  }
}
@media only screen and (max-width: 560px) {
  .partner__block {
    height: 400px !important;
  }
}
.home-swiper {
  margin: 156px auto;
  width: 90%;
}

.home_swiper-text {
  position: relative;
  width: 80%;
  margin: 20px auto;
}
.home_swiper-text span {
  font-family: "Raleway", sans-serif;
  font-size: 64px;
  line-height: 83px;
  text-shadow: 2px -6px 4px rgba(0, 0, 0, 0.25);
}

.prpl_txt {
  color: #4A1771;
}

.yel_txt {
  color: #eeee0e;
}

.sp_1 {
  position: absolute;
  top: -41px;
  left: 155px;
}

.get_more {
  margin-top: 75px;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .sp_1 {
    top: 65px;
    left: 15px;
  }

  .sp_1 img {
    width: 100%;
  }

  .home_swiper-text span {
    font-size: 24px;
    line-height: 48px;
  }
}
.info__circle {
  margin-top: 225px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info__circle > div {
  width: 43%;
  gap: 70px;
}

.info__title {
  font-family: "Raleway", sans-serif !important;
  font-size: 40px;
  line-height: 70px;
  text-transform: uppercase;
  color: #00ADB5 !important;
  text-shadow: 2px -6px 4px rgba(0, 0, 0, 0.25);
}

.info__description {
  font-family: "Raleway", sans-serif !important;
  font-size: 24px;
  line-height: 40px;
  color: #EEEEEE !important;
  text-align: left;
}

.circle__title {
  font-family: "Raleway", sans-serif !important;
  font-size: 24px;
  line-height: 31px;
  color: #EEEEEE !important;
}

.info__representation {
  position: relative;
  margin-top: 100px;
  width: 528px;
  height: 528px;
  border: 5px solid #00ADB5 !important;
  box-shadow: inset 9px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}

.circle {
  position: absolute;
  width: 159px;
  height: 157px;
  box-shadow: 9px 0 0 rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  align-items: center;
}

.not-active {
  background: #222831 !important;
}

.active_circle {
  background: #00ADB5 !important;
}

.comp {
  top: 401px;
  left: 38px;
}

.paint {
  left: 381px;
  top: 31px;
}

.info {
  left: 170px;
  top: -80px;
}

.seedling {
  left: 437px;
  top: 241px;
}

.book {
  left: 277px;
  top: 401px;
}

.shield {
  left: -77px;
  top: 222px;
}

.search {
  left: -40px;
  top: 31px;
}

.info__text p {
  width: 77%;
}

.mobile-swiper {
  display: none;
}

@media only screen and (max-width: 1381px) {
  .info__circle {
    flex-wrap: wrap;
  }

  .info__images {
    display: none;
  }

  .info__circle > div {
    width: 95%;
  }

  .info__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    text-align: justify;
  }

  .info__text p {
    width: 100%;
  }

  .mobile-swiper {
    display: block;
  }

  .mobile-swiper {
    width: 80%;
    margin: 0 auto 25px;
  }
}
@media only screen and (max-width: 767px) {
  .info__text p {
    width: 90%;
    margin: auto;
  }

  .info__description {
    font-size: 20px;
    line-height: 40px;
  }
}
.laboratory {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.laboratory > div {
  width: 45%;
}

.laboratory__title {
  font-family: "Raleway", sans-serif !important;
  font-size: 64px;
  line-height: 83px;
  text-transform: uppercase;
  color: #EEEEEE !important;
  text-shadow: 2px -6px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 35px;
}

.laboratory__title span {
  color: #00ADB5 !important;
  font-family: "Raleway", sans-serif !important;
}

.laboratory__description {
  font-family: "Raleway", sans-serif !important;
  font-size: 24px;
  line-height: 31px;
  color: #EEEEEE !important;
  width: 77%;
  text-align: left;
}

.pink_circle {
  width: 528px;
  height: 528px;
  background: #00ADB5 !important;
  box-shadow: 9px 0 0 rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1060px) {
  .laboratory {
    flex-direction: column;
    justify-content: center;
  }

  .laboratory .sec {
    margin: 20px auto;
    width: 90%;
    text-align: justify;
  }

  .laboratory__description {
    width: 95%;
  }

  .one {
    margin-left: 20%;
    order: 1;
    display: flex;
    justify-content: center;
  }

  .sec {
    order: 2;
  }

  .pink_circle {
    width: 340px;
    height: 340px;
  }
}
@media only screen and (max-width: 760px) {
  .pink_circle {
    width: 200px;
    height: 200px;
  }

  .pink_circle img {
    width: 50px;
  }

  .laboratory > div {
    width: 60%;
  }

  .one {
    margin-left: 0;
  }

  .laboratory__description {
    width: 90%;
    margin: auto;
    font-size: 20px;
    line-height: 40px;
  }
}
@media only screen and (max-width: 650px) {
  .laboratory__title {
    font-size: 37px;
  }
}
.stock__block {
  width: 87%;
  margin: 150px auto;
}

.stock__title {
  display: flex;
  justify-content: center;
}

.stock {
  width: 257px;
  height: 126px;
  background: #CAD011;
  border: 1px solid #E7DADA;
  box-sizing: border-box;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding-top: 25px;
  position: relative;
}

.stock__title p {
  font-family: "Raleway", sans-serif !important;
  font-size: 52px;
  line-height: 80px;
  color: #00ADB5 !important;
}

.stock_shadow {
  top: -15px;
  right: -20px;
  position: absolute;
  z-index: -5555;
  width: 257px;
  height: 126px;
  background: rgba(239, 113, 96, 0.79);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.stock__items {
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
  gap: 35px;
  flex-wrap: wrap;
}

.stock__items-container {
  display: flex;
  justify-content: space-around;
}

.sphare__block {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
  z-index: -9999999999;
  opacity: 0.4;
}

.sp_3, .sp_4, .sp_5, .sp_6, .sp_7 {
  position: absolute;
}

.sp_3 {
  right: 0px;
  top: 1597px;
}

.sp_4 {
  right: 0px;
  top: 2154px;
}

.sp_5 {
  left: 0px;
  top: 2441px;
}

.sp_6 {
  left: 0px;
  top: 2441px;
}

.sp_7 {
  top: 4700px;
}

.sp_7 img {
  width: 100%;
}

@media only screen and (max-width: 1056px) {
  .sphare__block {
    display: none;
  }
}
.contact_us_map {
  position: relative;
  margin-left: 10%;
  margin-bottom: 50px;
  border-radius: 20px;
  border: none;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
}

.contact_us_background {
  display: flex;
  /* min-height: 100vh; */
}

.contact_us_container {
  flex: 0 1 700px;
  margin: 20px auto 70px;
  padding: 10px;
}

.contact_us_screen {
  position: relative;
  background: #222831 !important;
  border-radius: 15px;
}

.contact_us_screen:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 20px;
  right: 20px;
  bottom: 0;
  border-radius: 15px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  z-index: -1;
}

.contact_us_screen-header {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background: #222831 !important;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.contact_us_screen-header-left {
  margin-right: auto;
}

.contact_us_screen-header-button {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 3px;
  border-radius: 8px;
  background: #EEEEEE !important;
}

.contact_us_screen-header-button.close_contact {
  background: #00ADB5 !important;
}

.contact_us_screen-header-button.maximize_contact {
  background: #393E46 !important;
}

.contact_us_screen-header-button.minimize_contact {
  background: #EEEEEE !important;
}

.contact_us_screen-header-right {
  display: flex;
}

.contact_us_screen-header-ellipsis {
  width: 3px;
  height: 3px;
  margin-left: 2px;
  border-radius: 8px;
  background: #222831 !important;
}

.contact_us_screen-body {
  display: flex;
}

.contact_us_screen-body-item {
  flex: 1;
  padding: 20px 50px;
}

.contact_us_screen-body-item.left {
  display: flex;
  flex-direction: column;
}

.contact_us_app-title {
  display: flex;
  flex-direction: column;
  position: relative;
  color: #00ADB5 !important;
  font-size: 24px;
  margin-bottom: 10px;
}

.contact_us_app-title-right {
  display: flex;
  flex-direction: column;
  position: relative;
  color: #00ADB5 !important;
  font-size: 24px;
  margin-bottom: 30px;
}

.contact_us_footer-icons {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.contact_us_footer-icons a {
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color: #393E46 !important;
  border-radius: 2px;
  font-size: 20px;
  color: #EEEEEE !important;
  text-align: center;
  line-height: 35px;
  margin-right: 3px;
  margin-bottom: 5px;
}

.contact_us_app-title:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 25px;
  height: 4px;
  background: #00ADB5 !important;
}

.contact_us_app-title-right:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: -10px;
  width: 100%;
  height: 4px;
  background: #00ADB5 !important;
}

.contact_us_footer-center {
  padding-top: 30px;
  width: 100%;
}

.contact_us_footer-center i {
  background-color: #33383b;
  color: #00ADB5 !important;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  margin: 10px 15px;
  vertical-align: middle;
  font-size: 17px;
  line-height: 38px;
}

.contact_us_footer-center p {
  display: inline-block;
  color: #EEEEEE !important;
  font-weight: 400;
  vertical-align: middle;
  margin: 0;
}

.contact_us_footer-center a {
  display: inline-block;
  color: #EEEEEE !important;
  font-weight: 400;
  vertical-align: middle;
  text-decoration: none;
  margin: 0;
}

.contact_us_footer-center a span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}

.contact_us_footer-center p span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}

.contact_us_app-form-group {
  margin-bottom: 15px;
}

.contact_us_app-form-group.message {
  margin-top: 40px;
}

.contact_us_app-form-group.buttons {
  margin-bottom: 0;
  text-align: right;
}

.contact_us_app-form-control {
  width: 100%;
  padding: 10px 0;
  background: none;
  border: none;
  border-bottom: 1px solid #393E46 !important;
  color: #ddd;
  font-size: 14px;
  text-transform: none;
  outline: none;
  transition: border-color 0.2s;
}

.contact_us_app-form-control::placeholder {
  color: #ddd;
}

.contact_us_app-form-control:focus {
  border-bottom-color: #EEEEEE !important;
}

.contact_us_app-form-button {
  background: none;
  border: none;
  color: #00ADB5 !important;
  font-size: 14px;
  cursor: pointer;
  outline: none;
}

.contact_us_app-form-button:hover {
  color: #00ADB5 !important;
}

.contact_us_credits {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #ffa4bd;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 16px;
  font-weight: normal;
}

.contact_us_credits-link {
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
}

.contact_us_dribbble {
  width: 20px;
  height: 20px;
  margin: 0 5px;
}

@media screen and (max-width: 520px) {
  .contact_us_screen-body {
    flex-direction: column;
  }

  .contact_us_screen-body-item.left {
    margin-bottom: 30px;
  }

  .contact_us_app-title {
    flex-direction: row;
  }

  .contact_us_app-title span {
    margin-right: 12px;
  }

  .contact_us_app-title:after {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .contact_us_screen-body {
    padding: 40px;
  }

  .contact_us_screen-body-item {
    padding: 0;
  }
}
.about_cars {
  width: 80%;
  margin: auto;
  padding: 100px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.about {
  width: 80%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.about > div,
.about_cars > div {
  width: 45%;
  height: 600px;
  margin-bottom: 50px;
}

.about__car_image {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.about__car_image > h1 {
  font-size: 40px;
  font-family: "Raleway", sans-serif !important;
  font-weight: normal;
  font-style: normal;
  color: #00ADB5 !important;
}

.about__description,
.about__car_image-circle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.about__car_image-circle {
  width: 500px;
  height: 500px;
  background: rgba(47, 47, 180, 0.82);
  border: 10px solid #4a1771;
  border-radius: 50%;
}

.about__description-title {
  color: #EEEEEE !important;
  font-family: "Raleway", sans-serif !important;
  font-size: 44px;
  line-height: 60px;
}

.about__description-title span {
  color: #e6b0d5;
  font-family: "Raleway", sans-serif;
}

.about__description_container {
  width: 80%;
  margin: 0 auto 150px;
}

.about__description-text {
  font-size: 20px;
  margin-top: 30px;
  line-height: 31px;
  color: #EEEEEE !important;
  font-family: "Raleway", sans-serif !important;
}

.about__description-text-2 {
  font-size: 20px;
  margin-top: 30px;
  line-height: 31px;
  color: #EEEEEE !important;
  font-family: "Raleway", sans-serif !important;
}

.btn-container {
  margin-top: 47px;
  display: flex;
  justify-content: left;
}

.about__description-text-p {
  font-size: 20px;
  line-height: 31px;
  color: #EEEEEE !important;
  margin-top: 30px;
  font-family: "Raleway", sans-serif !important;
}

.about__description-title-blue {
  font-size: 44px;
  line-height: 60px;
  margin-bottom: 30px;
  text-align: center;
  width: 200px;
  color: #EEEEEE !important;
  background: #09355d;
  box-shadow: -9px 18px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  font-family: "Raleway", sans-serif !important;
}

.about__description-title-red {
  font-size: 44px;
  line-height: 60px;
  margin-bottom: 30px;
  text-align: center;
  width: 200px;
  color: #EEEEEE !important;
  background: #c20e19;
  box-shadow: -9px 18px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  font-family: "Raleway", sans-serif !important;
}

.about__description-title-yellow {
  font-size: 44px;
  line-height: 60px;
  margin-bottom: 30px;
  text-align: center;
  width: 200px;
  color: black;
  font-weight: bold;
  background: #fcc000;
  box-shadow: -9px 18px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  font-family: "Raleway", sans-serif !important;
}

@media only screen and (max-width: 1580px) {
  .about > div {
    width: 50%;
  }
}
@media only screen and (max-width: 1281px) {
  .about__car_image-circle {
    width: 500px;
    height: 500px;
  }
}
@media only screen and (max-width: 767px) {
  .about_component > div {
    width: 95%;
    height: auto;
    margin-top: 30px;
  }

  .about__car_image-circle {
    height: 300px;
  }

  .about_cars {
    padding: 50px 0;
  }

  .about__car_image > h1 {
    font-size: 24px;
    margin-top: 20px;
  }
}
.presentation, .information {
  display: flex;
  justify-content: center;
  gap: 118px;
}

.presentation > div, .information > div {
  width: 40%;
}

.breadcrumbs {
  margin-top: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.breadcrumbs div {
  width: 85%;
  background: #090949;
  border: 1px solid #090949;
  box-sizing: border-box;
  border-radius: 5px;
  height: 50px;
  padding-top: 11px;
}

.breacrumbs__list {
  list-style-type: none;
  display: flex;
  justify-content: left;
  gap: 36px;
}

.breacrumbs__list:first-child {
  margin-left: 25px;
}

.breacrumbs__list li {
  height: 19px;
  font-family: sans-serif;
  font-size: 20px;
  line-height: 26px;
  text-transform: capitalize;
  color: #CCFBFF;
}

.presentation {
  margin-top: 100px;
}

.presentation__image {
  height: 503px;
  width: 423px;
  background: #090949;
  box-shadow: 10px 10px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px 20px;
}

.presentation__image-logo {
  display: flex;
  justify-content: right;
  padding: 13px 13px 0px 0px;
}

.presentation__image-logo img {
  width: 90px;
  height: 42px;
}

.presentation__image-img {
  border-radius: 5px;
  display: flex;
  justify-content: center;
}

.presentation__image-img img {
  width: 353px;
  height: 353px;
}

.ttk .title {
  font-family: sans-serif;
  font-weight: bold;
  font-size: 30px;
  line-height: 39px;
  color: #090949;
}

.ttk_description {
  margin-top: 50px;
  display: flex;
  justify-content: left;
  gap: 50px;
}

.ttk_description-title p, .ttk_description-description p {
  font-family: sans-serif;
  font-size: 24px;
  line-height: 31px;
  color: #000000;
  margin-bottom: 10px;
}

.price {
  width: 187px;
  height: 55px;
  font-family: sans-serif;
  font-size: 24px;
  line-height: 31px;
  color: #090949;
  text-align: center;
  padding-top: 13px;
}

.old_price {
  text-decoration: line-through;
  background: #DDF2F3;
  border: 1px solid #4CBAF8;
  box-sizing: border-box;
  border-radius: 20px 5px;
}

.new_price {
  background: #E31A56;
  border-radius: 5px 20px;
}

.btns__action {
  display: flex;
  justify-content: left;
  margin-top: 50px;
}

.card-body > .btns__action {
  margin-top: 0px;
}

.btns__action > div {
  width: 60px;
  height: 60px;
  background: #DDF2F3;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.card-body > .btns__action > div {
  width: 50px;
  height: 45px;
}

.btns__action > div > img {
  width: 20%;
  user-select: none;
}

.btns__add {
  margin-top: 40px;
  display: flex;
  justify-content: left;
  gap: 32px;
}

.btns__add .price {
  color: #fff;
}

.heart {
  text-align: center;
  padding-top: 17px;
  background-color: #fff;
  width: 51px;
  height: 51px;
  background: #DDF2F3;
  border-radius: 20px;
}

.colors_types {
  display: flex;
  justify-content: center;
  gap: 15px;
  background-color: #fff;
  height: 83px;
  border-radius: 20px;
  margin-top: 92px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.information .colors_types {
  width: 400px;
}

.colors_types div {
  width: 74px;
  height: 74px;
  border-radius: 20px;
}

.single__info {
  margin-top: 30px;
}

.single__info p {
  font-family: sans-serif;
  font-size: 18px;
  line-height: 23px;
  color: #CCFBFF;
}

.catalog_block {
  margin-top: 46px;
}
.catalog_block h1 {
  font-size: 50px;
}

.catalogs {
  background-color: #222831 !important;
  width: 100%;
  height: auto;
  padding: 20px 50px;
  border-radius: 8px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.catalog_type {
  height: 60px;
  border-radius: 8px;
  margin: 10px 0;
}

.b-dropdown {
  width: 100%;
  height: 100%;
}

.b-dropdown > button {
  background-color: #00ADB5 !important;
  color: #EEEEEE !important;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.b-dropdown > button:active {
  background-color: #00ADB5 !important;
}

.b-dropdown > button:hover, .b-dropdown > button:focus {
  background-color: #393E46 !important;
}

.b-dropdown > ul {
  width: 100%;
  background-color: #045470;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.b-dropdown > ul > li > button {
  width: 100%;
  color: white;
  background-color: #045470;
}

.b-dropdown > ul > li > button:hover {
  background-color: #009AAF;
  color: white;
}

.catalog_type > button {
  background-color: #00ADB5 !important;
  color: #EEEEEE !important;
  font-family: sans-serif;
  height: 100%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.catalog_type > button:active {
  background-color: #00ADB5 !important;
}

.catalog_type > button:hover, .b-button > button:focus {
  background-color: #393E46 !important;
}

.catalog_type > ul {
  width: 100%;
  background-color: #045470;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.catalog_type > ul > li > button {
  width: 100%;
  color: white;
  background-color: #045470;
}

.catalog_type > ul > li > button:hover {
  background-color: #009AAF;
  color: white;
}

@media (max-width: 1056px) {
  .catalogs {
    padding: 20px 0px;
  }
}
@media only screen and (max-width: 576px) {
  .catalog_type button {
    font-size: 12px;
  }
}
/*Header and logo styling*/
.admin_header {
  position: absolute;
  width: 100%;
  z-index: 3;
  height: 46px;
  background-color: #fff;
  border-bottom: 1px solid #393E46 !important;
}

/* logo in header(mobile version) and side-nav (tablet & desktop) */
.admin_logo {
  height: 44px;
  padding: 10px;
  font-weight: 700;
  color: #00ADB5 !important;
}

.admin_wrapper td, .admin_wrapper tr, .admin_wrapper th {
  border-color: #393E46 !important;
}

.admin_header .admin_logo {
  color: #233245;
}

.admin_side-nav .admin_logo {
  background-color: #222831 !important;
  color: #fff;
}

.admin_header .admin_logo {
  float: left;
}

.admin_header .admin_logo {
  height: 44px;
  z-index: 1;
  padding: 10px;
  font-weight: 700;
  background-color: #222831 !important;
}

.admin_logo i {
  font-size: 22px;
}

.admin_logo span {
  margin-left: 5px;
  text-transform: uppercase;
}

.admin_nav-trigger {
  position: relative;
  float: right;
  width: 20px;
  height: 44px;
  right: 30px;
  display: block;
}

.admin_nav-trigger span, .admin_nav-trigger span:before, span:after {
  width: 20px;
  height: 2px;
  background-color: #222831 !important;
  position: absolute;
}

.admin_nav-trigger span {
  top: 50%;
}

.admin_nav-trigger span:before, .admin_nav-trigger span:after {
  content: "";
}

.admin_nav-trigger span:before {
  top: -6px;
}

.admin_nav-trigger span:after {
  top: 6px;
}

/* style for side navigation */
.admin_side-nav {
  position: absolute;
  width: 220px;
  height: 100vh;
  background-color: #393E46 !important;
  z-index: 1;
  display: none;
}

.admin_side-nav.visible {
  display: block;
}

.admin_side-nav ul {
  margin: 0;
  padding: 0;
}

.admin_side-nav ul li {
  padding: 16px 16px;
  border-bottom: 1px solid #222831 !important;
  position: relative;
}

.admin_side-nav ul li.active:before {
  content: "";
  position: absolute;
  width: 4px;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #eaedf3;
}

.admin_active_a {
  background: #302d43;
  color: #fff;
  border-right: 5px solid #00ADB5 !important;
  padding: 5px;
  transition: all 0.25s ease-in-out;
}

.admin_wrapper {
  background-color: #EEEEEE !important;
}

.admin_wrapper > .container {
  height: 90vh;
}

.admin_side-nav ul li a {
  color: #fff;
  display: block;
  text-decoration: none;
  transition: all 0.25s ease-in-out;
}

.admin_side-nav ul li a.router-link-active,
.admin_side-nav ul li a.router-link-exact-active {
  background: #302d43;
  color: #fff;
  border-right: 5px solid #00ADB5 !important;
  padding: 5px;
}

/*hover over*/
.admin_side-nav ul li a:hover {
  background: #302d43;
  color: #fff;
  border-right: 5px solid #00ADB5 !important;
  padding: 5px;
  transition: all 0.25s ease-in-out;
}

.admin_side-nav ul li i {
  color: #00ADB5 !important;
  min-width: 20px;
  text-align: center;
}

.admin_side-nav ul li span:nth-child(2) {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 580;
}

/* main content styles */
.admin_main-content {
  padding: 43px;
  margin-top: 0;
  padding: 0;
  padding-top: 46px;
  height: 100%;
  overflow: scroll;
}

.admin_main-content .title {
  background-color: #eef1f7;
  border-bottom: 2px solid #393E46 !important;
  padding: 12px 470px;
  font-weight: 700;
  color: #333;
  font-size: 18px;
}

.admin_wrapper {
  padding-left: 200px;
  height: 100vh;
  padding-top: 50px;
  overflow-y: scroll;
}

.admin_table {
  height: auto;
  overflow-x: auto;
}

.admin_th {
  text-align: center;
}

.admin_td {
  text-align: center;
  font-family: sans-serif;
}
.admin_td img {
  width: 80px;
  height: 80px;
}

@media screen and (min-width: 600px) {
  .admin_header {
    background-color: #393E46 !important;
    z-index: 1;
  }

  .admin_header .logo {
    display: none;
  }

  .admin_nav-trigger {
    display: none;
  }

  .admin_nav-trigger span, .admin_nav-trigger span:before, span:after {
    background-color: #fff;
  }

  .admin_side-nav {
    display: block;
    width: 70px;
    z-index: 2;
  }

  .admin_side-nav ul li span:nth-child(2) {
    display: none;
  }

  .admin_side-nav .logo i {
    padding-left: 12px;
  }

  .admin_side-nav .logo span {
    display: none;
  }

  .admin_side-nav ul li i {
    font-size: 25px;
  }

  .admin_side-nav ul li a {
    text-align: center;
  }

  .admin_main-content {
    margin-left: 70px;
  }
}
/* set element styles for desktop */
@media screen and (min-width: 800px) {
  .admin_side-nav {
    width: 200px;
  }

  .admin_side-nav ul li span:nth-child(2) {
    display: inline-block;
  }

  .admin_side-nav ul li i {
    font-size: 16px;
  }

  .admin_side-nav ul li a {
    text-align: left;
  }

  .admin_side-nav .logo i {
    padding-left: 0;
  }

  .admin_side-nav .logo span {
    display: inline-block;
  }

  .admin_main-content {
    margin-left: 200px;
  }
}
/* main content in box container */
.admin_main {
  display: flex;
  flex-flow: row wrap;
}

.admin_widget {
  flex-basis: 300px;
  flex-grow: 10;
  height: 400px;
  margin: 15px;
  border-radius: 6px;
  background-color: #ffffff;
  position: relative;
}

.admin_widget .title {
  background-color: #eef1f7;
  border-bottom: 1px solid #393E46 !important;
  padding: 10px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: #617085;
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .admin_wrapper {
    padding-left: 20px;
    padding-top: 50px;
  }
}
.product__card__admin {
  height: 430px;
  background: #222831;
  box-shadow: 10px 10px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px 20px;
  padding: 10px;
}

.product_presentation_admin {
  display: flex;
  justify-content: center;
}

.product_img_admin {
  width: 90%;
  height: 250px;
  background-color: #fff;
  overflow: hidden;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}
.product_img_admin:hover {
  opacity: 0.7;
}

.product_img_admin > img {
  width: 70px;
  height: 70px;
}

.main__goods {
  display: flex;
  grid-gap: 3rem;
  padding: 3rem;
  box-shadow: 0 1px 0 0px rgba(0, 0, 0, 0.12);
}

.goods__photos {
  display: flex;
  justify-content: center;
}

.goods__main_preview {
  position: relative;
  width: 100%;
  height: 350px;
  border-radius: 0.6rem;
  overflow: hidden;
}
.goods__main_preview img {
  width: 100%;
  height: 100%;
}

.goods__photo_list {
  list-style: none;
  margin: 2rem 0 0;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
}
.goods__photo_list li {
  display: inline-block;
}
.goods__photo_list img {
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 0.6rem;
}

.goods__details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.goods__company {
  color: #00ADB5 !important;
  font-family: sans-serif;
  margin: 0.5rem 0;
}

.goods__title {
  font-size: 2rem;
  color: #EEEEEE !important;
  font-family: sans-serif;
  margin: 0;
}

.goods__description {
  margin: 1rem 0;
  line-height: 1.3;
  color: #EEEEEE !important;
  font-family: sans-serif;
}

.goods__sale__price {
  display: inline-block;
  margin: 0 0.6rem 0 0;
  color: #00ADB5 !important;
  font-family: sans-serif;
}

.goods__discount {
  color: #222831 !important;
  background: #EEEEEE !important;
  padding: 0.1rem 0.5rem;
  border-radius: 0.1rem;
  font-family: sans-serif;
}

.goods__original__price {
  color: #999;
  text-decoration: line-through;
  margin: 0.6rem 0;
  font-family: sans-serif;
}

.main__related {
  margin: 2rem 0;
}

.related__title {
  color: #00ADB5 !important;
  margin: 0 0 1.5rem;
}

.related__prev_next {
  display: none;
}

.related__prev_next .related__prev,
.related__prev_next .related__next {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 25px;
  background: none;
  border: none;
  padding: 1rem 1.5rem;
  color: #412;
  cursor: pointer;
  border-radius: 50%;
}
.related__prev_next .related__prev:hover,
.related__prev_next .related__next:hover {
  color: #000;
  background: #0001;
}

.related__prev_next .related__next {
  right: 0;
  left: auto;
}

.related__goods_viewpoint {
  position: relative;
}

.related__goods {
  list-style: none;
  display: flex;
  gap: 0 2rem;
  padding: 0;
  margin: 0;
  overflow: scroll;
  transition: 0.3s all;
  margin-left: 0vw;
}
.related__goods li {
  flex: 1;
}
.related__goods img {
  width: 100%;
  height: 15vw;
  object-fit: cover;
  border-radius: 0.2rem;
}

@media screen and (max-width: 752px) {
  .main__goods {
    padding: 0;
  }

  .goods__main_preview {
    border-radius: 0;
    height: 300px;
  }

  .goods__photo_list {
    display: none;
  }

  .goods__details {
    padding: 0 2rem 2rem;
  }

  .related__title {
    margin-left: 2rem;
  }

  .related__goods {
    gap: 0;
    margin-left: 0vw;
  }
  .related__goods img {
    width: 100vw;
    height: 300px;
    border-radius: 0;
  }

  .related__prev_next {
    display: inline-block;
  }
}
@media screen and (max-width: 862px) {
  .main__goods {
    flex-direction: column;
  }
}
.lamborghini_huracan {
  transform: scale(3);
  /* temporary scale to view level of details */
  border: 1px solid white;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lamborghini_huracan .fil23 {
  fill: #FEFEFE;
}

.lamborghini_huracan .fil39 {
  fill: #EBDDE2;
}

.lamborghini_huracan .fil36 {
  fill: #E6E7E6;
}

.lamborghini_huracan .fil45 {
  fill: #999999;
}

.lamborghini_huracan .fil41 {
  fill: #616161;
}

.lamborghini_huracan .window_glass {
  fill: var(--window-color);
  opacity: 0.5;
  transition: fill 1s;
}

/* window glass color */
.lamborghini_huracan .fil21 {
  fill: #282829;
}

.lamborghini_huracan .fil1 {
  fill: #282829;
}

.lamborghini_huracan .fil38 {
  fill: #292929;
}

.lamborghini_huracan.fil28 {
  fill: black;
}

.lamborghini_huracan .body_paint {
  fill: var(--body-color);
  transition: fill 1s;
}

/* car paint */
.lamborghini_huracan .fil37 {
  fill: #2A3539;
}

.lamborghini_huracan .fil26 {
  fill: #3F5563;
}

.lamborghini_huracan .fil0 {
  fill: transparent;
}

.lamborghini_huracan .fil30 {
  fill: #BCC5CE;
}

.lamborghini_huracan .fil29 {
  fill: white;
}

.lamborghini_huracan .fil43 {
  fill: #FEFEFE;
  fill-rule: nonzero;
}

.lamborghini_huracan .fil40 {
  fill: #282829;
  fill-rule: nonzero;
}

.lamborghini_huracan .fil18 {
  fill: #040404;
  fill-rule: nonzero;
}

.lamborghini_huracan .fil7 {
  fill: #282829;
  fill-rule: nonzero;
}

.lamborghini_huracan .fil8 {
  fill: #292929;
  fill-rule: nonzero;
}

.lamborghini_huracan .fil15 {
  fill: #350700;
  fill-rule: nonzero;
}

.lamborghini_huracan .fil17 {
  fill: #373435;
  fill-rule: nonzero;
}

.lamborghini_huracan .fil10 {
  fill: #3F4040;
  fill-rule: nonzero;
}

.lamborghini_huracan .fil27 {
  fill: #607785;
  fill-rule: nonzero;
}

.lamborghini_huracan .fil4 {
  fill: #616161;
  fill-rule: nonzero;
}

.lamborghini_huracan .fil11 {
  fill: #745012;
  fill-rule: nonzero;
}

.lamborghini_huracan .fil6 {
  fill: #81949B;
  fill-rule: nonzero;
}

.lamborghini_huracan .fil3 {
  fill: #999999;
  fill-rule: nonzero;
}

.lamborghini_huracan .fil42 {
  fill: #AEB7C0;
  fill-rule: nonzero;
}

.lamborghini_huracan .fil13 {
  fill: #D19300;
  fill-rule: nonzero;
}

.lamborghini_huracan .fil19 {
  fill: #DBDE35;
  fill-rule: nonzero;
}

.lamborghini_huracan .fil12 {
  fill: #E4AA00;
  fill-rule: nonzero;
}

.lamborghini_huracan .fil9 {
  fill: #E6E7E6;
  fill-rule: nonzero;
}

.lamborghini_huracan .fil14 {
  fill: #F8C311;
  fill-rule: nonzero;
}

.lamborghini_huracan .fil16 {
  fill: white;
  fill-rule: nonzero;
}

.lamborghini_huracan .fil24 {
  fill: url(#id126);
}

.lamborghini_huracan .fil44 {
  fill: url(#id127);
}

.lamborghini_huracan .fil34 {
  fill: url(#id128);
}

.lamborghini_huracan .fil31 {
  fill: url(#id129);
}

.lamborghini_huracan .fil35 {
  fill: url(#id130);
}

.lamborghini_huracan .fil33 {
  fill: url(#id131);
}

.lamborghini_huracan .fil32 {
  fill: url(#id132);
}

.lamborghini_huracan .fil25 {
  fill: url(#id133);
  fill-rule: nonzero;
}

.lamborghini_huracan .fil20 {
  fill: url(#id134);
  fill-rule: nonzero;
}

.lamborghini_huracan .view_mirror {
  fill: var(--body-color);
  transition: fill 1s;
}

/* view mirror glass color */
.lamborghini_huracan .fil2 {
  fill: url(#id135);
  fill-rule: nonzero;
}

.lamborghini_huracan .fil5 {
  fill: url(#id136);
  fill-rule: nonzero;
}

.lamborghini_huracan #wheel_back #disk,
.lamborghini_huracan #wheel_back #pattern {
  transform-origin: 8.35px 9.9px;
  transform: rotate(30deg);
}

.lamborghini_huracan #wheel_front #disk,
.lamborghini_huracan #wheel_front #pattern {
  transform-origin: 39.61px 9.88px;
  transform: rotate(30deg);
}

.lamborghini_huracan #wheel_front #disk,
.lamborghini_huracan #wheel_front #pattern,
.lamborghini_huracan #wheel_back #disk,
.lamborghini_huracan #wheel_back #pattern {
  animation-name: wheel;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes wheel {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(800deg);
  }
}
@media screen and (max-width: 1056px) {
  .lamborghini_huracan {
    display: none;
  }
}