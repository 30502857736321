
// Website fonts

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;1,300&display=swap');
$font-family: 'Raleway', sans-serif !important;


// Website colors

$first-color: #222831 !important;
$second-color: #393E46 !important;
$third-color: #00ADB5 !important;
$fourth-color: #EEEEEE !important;
$error-color: #FFBABA;
